import { 
  // createAsyncThunk, 
  createSlice 
} from '@reduxjs/toolkit';
// import md5 from 'md5';
import { RootState } from '../../app/store';

// const documents = {
//   'loadedFile': 'documents/loadedFile',
//   'loadedDocument': 'documents/loadedDocument',
//   'documentCategoryDetected': 'documents/documentCategoryDetected',
// };

type TDocument = {
  name: string;
  type: 'unknown' | 'bank statement' | 'ERP';
  content: {[sheetName: string]: string[][]};
};



export interface DocumentsState {
  documents: {[documentId: string]: TDocument};
  status: 'idle' | 'loading' | 'failed';
}

const initialState: DocumentsState = {
  documents: {},
  status: 'idle',
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // 'documents/loadedFile/fulfilled': (state, action) => {
    //   const firstTabName = Object.keys(action.payload.content)[0];
    //   const firstSheet = action.payload.content[firstTabName];
    //   if (firstSheet && firstSheet[0][0] === 'Booking date') {
    //     state.documents[action.payload.id] = {
    //       ...state.documents[action.payload.id],
    //       type: 'bank statement' as 'bank statement',
    //     };
    //     // state.documents {id: action.payload.id, type: 'bank statement' as 'bank statement'};
    //   } else if (firstSheet && firstSheet[0][0] === 'DETAILED LEDGER REPORT(S) User:') {
    //     console.log('detectDocumentCategory', 'ERP');
    //     state.documents[action.payload.id] = {
    //       ...state.documents[action.payload.id],
    //       type: 'ERP' as 'ERP',
    //     };
    //     // return {id: action.payload.id, type: 'ERP' as 'ERP'};
    //   } 
    // },
    // fullFilled: (state, action) => {
    //   const firstTabName = Object.keys(action.payload.content)[0];
    //     const firstSheet = action.payload.content[firstTabName];
    //     if (firstSheet && firstSheet[0][0] === 'Booking date') {
    //       state.documents[action.payload.id] = {
    //         ...state.documents[action.payload.id],
    //         type: 'bank statement' as 'bank statement',
    //       };
    //       // state.documents {id: action.payload.id, type: 'bank statement' as 'bank statement'};
    //     } else if (firstSheet && firstSheet[0][0] === 'DETAILED LEDGER REPORT(S) User:') {
    //       console.log('detectDocumentCategory', 'ERP');
    //       state.documents[action.payload.id] = {
    //         ...state.documents[action.payload.id],
    //         type: 'ERP' as 'ERP',
    //       };
    //     }
    //   },
    // addDocument: (state, action) => {
    //   const documentDTO: TDocumentDTO = {
    //     id: md5(action.payload.name+JSON.stringify(action.payload.content)),
    //     name: action.payload.name,
    //     type: 'unknown' as 'unknown',
    //     content: action.payload.content,
    //   };
    //   console.log('addDocument', documentDTO);
    //   state.documents[action.payload.id] = documentDTO;
    // }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    // builder
    //   .addCase(addDocumentAsync.pending, (state) => {
    //     state.status = 'loading';
    //     console.log('pending');
    //   })
    //   .addCase(addDocumentAsync.fulfilled, (state, action) => {
    //     state.status = 'idle';
    //     console.log('idle');
    //     console.log('action.payload.id', action.payload.id);
    //     state.documents[action.payload.id] = {
    //       ...state.documents[action.payload.id],
    //       name: action.payload.name,
    //       content: action.payload.content,
    //     };
    //     console.log('completed addDocumentAsync.fulfilled', addDocumentAsync.fulfilled.name);
    //     this.publishEvent({name: 'test', payload: 'whatevs'});
    //   })
    //   .addCase(addDocumentAsync.rejected, (state) => {
    //     state.status = 'failed';
    //   });
    // builder
    //   .addCase(detectDocumentCategoryAsync.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(detectDocumentCategoryAsync.fulfilled, (state, action) => {
    //     state.status = 'idle';
    //     if (action.payload) {
    //       state.documents[action.payload.id] = {
    //         ...state.documents[action.payload.id],
    //         type: action.payload.type,
    //       };
    //     }
    //   })
    //   .addCase(detectDocumentCategoryAsync.rejected, (state) => {
    //     state.status = 'failed';
    //   });
  },
});

export const selectDocuments = (state: RootState) => state.documents;

export default documentsSlice.reducer;
