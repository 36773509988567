import { BankTransactionGroup } from '../../BankTransactionGroup';
import { Reconciliation } from '../../Reconciliation';
import { OperationType, RECONCILED, RuleNames, TOO_HIGH_NUMBER, UN_RECONCILED } from '../../common';
import { GetBankTransactionsOrderedByAmountReturn, getUnreconciledBankTransactionsOrderedByAmount } from '../getUnreconciledBankTransactionsOrderedByAmount';
import { getUnreconciledLedgerEntriesWithAmount } from '../getUnreconciledLedgerEntriesWithAmount';
import { getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange } from '../getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange';
import { GetLedgerEntriesWithSameDateAndAmountRangeReturn, getUnreconciledLedgerEntriesWithSameDateAndAmountRange } from '../getUnreconciledLedgerEntriesWithSameDateAndAmountRange';
import { getUnreconciledLedgerEntriesWithTermTags } from '../getUnreconciledLedgerEntriesWithTermTags';
import { Candidate, findCombinations4 } from '../helpers';

class Rule4 {
  private r: Reconciliation;
  private rule: RuleNames;

  constructor(reconciliation: Reconciliation, rule: RuleNames) {
    this.r = reconciliation;
    this.rule = rule;
  }

  run(): void {
    const rule = this.rule;
    if (Reconciliation.db) this.r.populateDB(Reconciliation.db);
    
    const state: {
      BANK_IDs_MATCH: boolean | null,
      AMOUNTS_MATCH: boolean | null,
      AMOUNTS_UNIQUE: boolean | null,
      DATES_MATCH: boolean | null,
      DATES_UNIQUE: boolean | null,
      TAGS_MATCH: boolean | null,
      TAGS_COMBINATION_UNIQUE: boolean | null,
    } = {
      BANK_IDs_MATCH: null,
      AMOUNTS_MATCH: null,
      AMOUNTS_UNIQUE: null,
      DATES_MATCH: null,
      DATES_UNIQUE: null,
      TAGS_MATCH: null,
      TAGS_COMBINATION_UNIQUE: null,
    };
    state.BANK_IDs_MATCH = rule === RuleNames.Rule4_1;
    let reconciliations = 0;
    let pass = 0;
    const GROUP_TO_DEBUG = 'X';
    do {
      pass++;
      const message = `${rule} pass ${pass}`;
      this.r.setMessage(message);
      this.r.updateProgress();
      // Get unreconciled transactions ordered by amount desc
      const bankTransactionsOrderedByAmount = getUnreconciledBankTransactionsOrderedByAmount(Reconciliation.db);
      // For each transaction
      for (let i = 0; i < bankTransactionsOrderedByAmount.length; i++) {
        this.r.setMessage(message + ' - ' + this.r.getBankStatement().getReconciledTransactions().length + ' / ' + this.r.getBankStatement().getTransactions().length);
        this.r.updateProgress();
        state.AMOUNTS_MATCH = null;
        state.AMOUNTS_UNIQUE = null;
        state.DATES_MATCH = null;
        state.DATES_UNIQUE = null;
        state.TAGS_MATCH = null;
        state.TAGS_COMBINATION_UNIQUE = null;
        const transaction = this.r.getBankStatement().getTransactions()[bankTransactionsOrderedByAmount[i].id - 1];
        if (transaction.getStatus() === RECONCILED) continue;
        const ledgerEntriesWithSameDateAndAmountRange = getUnreconciledLedgerEntriesWithSameDateAndAmountRange(Reconciliation.db, transaction, bankTransactionsOrderedByAmount);
        state.DATES_MATCH = ledgerEntriesWithSameDateAndAmountRange.length > 0;
        for (let j=0; j < ledgerEntriesWithSameDateAndAmountRange.length; j++) {
          const ledgerEntry = ledgerEntriesWithSameDateAndAmountRange[j];
          const targetAmount = (transaction.getAccountingAmount() + ledgerEntry.amount) * -1;
          const allOtherUnreconciledTransactions = getUnreconciledBankTransactionsOrderedByAmount(Reconciliation.db).filter((t: GetBankTransactionsOrderedByAmountReturn) => t.id !== transaction.getId());
          const allOtherTransactionsOnTheSameDate = allOtherUnreconciledTransactions.filter((t: GetBankTransactionsOrderedByAmountReturn) => t.date === transaction.getDate().getTime());
          const allOtherTransactionsWithinTheAmountRangeOnTheSameDate = allOtherTransactionsOnTheSameDate.filter((t: GetBankTransactionsOrderedByAmountReturn) => ((t.amount < 0 && t.amount >= targetAmount) || (t.amount > 0 && t.amount <= targetAmount))).filter((t: any) => rule === RuleNames.Rule4_2 || t.bankId === transaction.getBankId());
          // Get all combinations of transactions that sum up to the target amount (i.e. N transactions to 1 ledger)
          const combinations = findCombinations4(allOtherTransactionsWithinTheAmountRangeOnTheSameDate, targetAmount, transaction.operationType as OperationType);
          const bankTransactionGroup = new BankTransactionGroup(this.r.bankStatement);
          bankTransactionGroup.addTransaction(transaction);
          state.AMOUNTS_MATCH = combinations.length > 0;
          if (combinations.length === 0) continue;
          else if (combinations.length === 1) {
            combinations[0].forEach((t: Candidate) => {
              bankTransactionGroup.addTransaction(this.r.bankStatement.getTransactions()[t.id-1]);
            });
          } else {
            // find the combination that has the least average distance based on id
            // if there are multiple combinations with the same average distance, then 
            // we will choose the one that contains the transaction with the id closest to the main transaction
            const currentWinningCombination = { id: -TOO_HIGH_NUMBER, averageDistance: TOO_HIGH_NUMBER};
            for (let i=0; i < combinations.length; i++) {
              const combination = combinations[i];
              const mainTransactionId = transaction.getId();
              const candidateAverageDistance = combination.reduce((acc: number, t: any) => acc + Math.abs(t.id - mainTransactionId), 0) / combination.length;
              if (currentWinningCombination.averageDistance > candidateAverageDistance) {
                currentWinningCombination.id = i;
                currentWinningCombination.averageDistance = candidateAverageDistance;
              } else if (currentWinningCombination.averageDistance === candidateAverageDistance) {
                // find the transaction in combinations[i] with the id closest to the main transaction
                const candidateCombinationClosestId = combinations[i].reduce((acc: number, t: any) => Math.abs(t.id - mainTransactionId) < Math.abs(acc - mainTransactionId) ? t.id : acc, TOO_HIGH_NUMBER);
                const currentWinningCombinationClosestId = combinations[currentWinningCombination.id].reduce((acc: number, t: any) => Math.abs(t.id - mainTransactionId) < Math.abs(acc - mainTransactionId) ? t.id : acc, TOO_HIGH_NUMBER);
                if (Math.abs(candidateCombinationClosestId - mainTransactionId) <= Math.abs(currentWinningCombinationClosestId - mainTransactionId)) {
                  currentWinningCombination.id = i;
                  currentWinningCombination.averageDistance = candidateAverageDistance;
                }
              }
            }
            currentWinningCombination.id !== -TOO_HIGH_NUMBER && combinations[currentWinningCombination.id].forEach((t: any) => {
              bankTransactionGroup.addTransaction(this.r.bankStatement.getTransactions()[t.id-1])
            });
          }
          // check if there is another transaction group with the same amount
          const nonUniqueTransactionCombinations = findCombinations4(allOtherUnreconciledTransactions, ledgerEntry.amount * -1, transaction.operationType as OperationType);
          const nonUniqueLedgerEntries = getUnreconciledLedgerEntriesWithAmount(Reconciliation.db, ledgerEntry.amount).filter((l: any) => l.id !== ledgerEntry.id);

          const transactionCombinationIsUniqueBasedOnAmount = (nonUniqueTransactionCombinations.length === 0 && nonUniqueLedgerEntries.length === 0);
          state.AMOUNTS_UNIQUE = transactionCombinationIsUniqueBasedOnAmount;
          if (transactionCombinationIsUniqueBasedOnAmount) {
            this.r.reconcile(bankTransactionGroup, [ledgerEntry.id], rule, state);
          } else {
            // if there are multiple matches, we will proceed in checking if the tags match
            // if there are unique matches, then we reconcile the ledger entry with the bank statement transaction group
            // if some matches are not unique we decide based on the uniqueness of the date if we will suggest or remain unreconciled
            // Finally, we need to consider the remaining transactions and entries without taking into account their dates
            // and based the tags and their uniqueness we will decide if we will reconcile, suggest or keep unreconciled
            const commonTermStatistics = { totalCount: 0, conflictsCount: 0 };
            const commonTermTags = this.r.getCommonTermTags(bankTransactionGroup, this.r.ledger.getEntries()[ledgerEntry.id-1]);
            state.TAGS_MATCH = commonTermTags.length > 0;
            if (state.TAGS_MATCH) {
              commonTermTags.forEach((tag: string) => {
                commonTermStatistics.totalCount++;
                const transactions = findCombinations4(allOtherTransactionsOnTheSameDate, ledgerEntry.amount * -1, transaction.operationType as OperationType);
                if (transactions) {
                  let listOfTransactionIdsToCheck: number[] = [];
                  transactions.forEach((row: any) => row.forEach((t: any) => listOfTransactionIdsToCheck.push(t.id)));
                  listOfTransactionIdsToCheck = [...new Set(listOfTransactionIdsToCheck)];
                  if (GROUP_TO_DEBUG === bankTransactionGroup.getId()) console.log('listOfTransactionIdsToCheck', listOfTransactionIdsToCheck);
                  const conflictingTransactions = this.r.bankStatement.getTransactions().filter((t: any) => t.getStatus() === UN_RECONCILED && listOfTransactionIdsToCheck.includes(t.getId()) && t.getTags().includes(tag));
                  if (conflictingTransactions.length > 0) commonTermStatistics.conflictsCount++;
                  if (GROUP_TO_DEBUG === bankTransactionGroup.getId()) console.log('commonTermStatistics', commonTermStatistics, tag, conflictingTransactions);
                }
                const conflictingLedgerEntries = getUnreconciledLedgerEntriesWithTermTags(Reconciliation.db, tag, {date: transaction.getDate().getTime(), amount: bankTransactionGroup.getAmount()});
                if (conflictingLedgerEntries.length > 0) commonTermStatistics.conflictsCount++;
                if (GROUP_TO_DEBUG === bankTransactionGroup.getId()) console.log('commonTermStatistics', commonTermStatistics, tag, conflictingLedgerEntries);
              });
              state.TAGS_COMBINATION_UNIQUE = (commonTermStatistics.totalCount - commonTermStatistics.conflictsCount) / commonTermStatistics.totalCount > 0.5; // @TODO replace number with variable
            }
            if (state.TAGS_MATCH && state.TAGS_COMBINATION_UNIQUE) {
              // console.log('reconcile', bankTransactionGroup.getId(), [ledgerEntry.id]);
              this.r.reconcile(bankTransactionGroup, [ledgerEntry.id], rule, state);
            } else {
              const nonUniqueTransactionCombinationsOnSameDate = findCombinations4(allOtherTransactionsOnTheSameDate, ledgerEntry.amount * -1, transaction.operationType as OperationType);
              const nonUniqueLedgerEntriesOnSameDate = ledgerEntriesWithSameDateAndAmountRange.filter((l: GetLedgerEntriesWithSameDateAndAmountRangeReturn) => l.id !== ledgerEntry.id && l.amount === ledgerEntry.amount);
              if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('nonUniqueTransactionCombinationsOnSameDate', nonUniqueTransactionCombinationsOnSameDate);
              if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('nonUniqueLedgerEntriesOnSameDate', nonUniqueLedgerEntriesOnSameDate);
              state.DATES_UNIQUE = (nonUniqueTransactionCombinationsOnSameDate.length === 0 && nonUniqueLedgerEntriesOnSameDate.length === 0);
              if (state.DATES_UNIQUE) {
                this.r.suggest(bankTransactionGroup, [ledgerEntry.id], rule, state);
              }
            }
          }
          if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('state', state);
        }

        // Ledger entries with same amount but different dates
        if (transaction.getStatus() === RECONCILED) continue;
        // Get all entries with the different date that are within the transaction amount
        // minus the sum of all credit amounts and plus the sum of all other transaction amounts of the same date
        const ledgerEntriesWithDifferentDateAndSameAmountRange = getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange(Reconciliation.db, transaction, bankTransactionsOrderedByAmount);
        for (let j=0; j < ledgerEntriesWithDifferentDateAndSameAmountRange.length; j++) {
          state.DATES_MATCH = false;
          state.DATES_UNIQUE = null;
          const ledgerEntry = ledgerEntriesWithDifferentDateAndSameAmountRange[j];
          // if (transaction.getAccountingAmount() === ledgerEntry.amount) continue;
          const targetAmount = (transaction.getAccountingAmount() + ledgerEntry.amount) * -1;
          const allOtherTransactionsOnTheSameDate = getUnreconciledBankTransactionsOrderedByAmount(Reconciliation.db).filter((t: GetBankTransactionsOrderedByAmountReturn) => t.id !== transaction.getId() && t.date === transaction.getDate().getTime());
          const allOtherTransactionsWithinTheAmountRangeOnTheSameDate = allOtherTransactionsOnTheSameDate.filter((t: GetBankTransactionsOrderedByAmountReturn) => ((t.amount < 0 && t.amount >= targetAmount) || (t.amount > 0 && t.amount <= targetAmount))).filter((t: any) => rule === RuleNames.Rule4_2 || t.bankId === transaction.getBankId());
          const combinations = findCombinations4(allOtherTransactionsWithinTheAmountRangeOnTheSameDate, targetAmount, transaction.operationType as OperationType);
          const bankTransactionGroup = new BankTransactionGroup(this.r.bankStatement);
          bankTransactionGroup.addTransaction(transaction);
          state.AMOUNTS_MATCH = combinations.length > 0;
          if (combinations.length === 0) continue;
          else if (combinations.length === 1) {
            combinations[0].forEach((t: Candidate) => {
              bankTransactionGroup.addTransaction(this.r.bankStatement.getTransactions()[t.id-1]);
            });
          } else {
            // find the combination that has the least average distance based on id
            // if there are multiple combinations with the same average distance, then 
            // we will choose the one that contains the transaction with the id closest to the main transaction
            const currentWinningCombination = { id: -TOO_HIGH_NUMBER, averageDistance: TOO_HIGH_NUMBER};
            for (let i=0; i < combinations.length; i++) {
              const combination = combinations[i];
              const mainTransactionId = transaction.getId();
              const candidateAverageDistance = combination.reduce((acc: number, t: any) => acc + Math.abs(t.id - mainTransactionId), 0) / combination.length;
              if (currentWinningCombination.averageDistance > candidateAverageDistance) {
                currentWinningCombination.id = i;
                currentWinningCombination.averageDistance = candidateAverageDistance;
              } else if (currentWinningCombination.averageDistance === candidateAverageDistance) {
                // find the transaction in combinations[i] with the id closest to the main transaction
                const candidateCombinationClosestId = combinations[i].reduce((acc: number, t: any) => Math.abs(t.id - mainTransactionId) < Math.abs(acc - mainTransactionId) ? t.id : acc, TOO_HIGH_NUMBER);
                const currentWinningCombinationClosestId = combinations[currentWinningCombination.id].reduce((acc: number, t: any) => Math.abs(t.id - mainTransactionId) < Math.abs(acc - mainTransactionId) ? t.id : acc, TOO_HIGH_NUMBER);
                if (Math.abs(candidateCombinationClosestId - mainTransactionId) <= Math.abs(currentWinningCombinationClosestId - mainTransactionId)) {
                  currentWinningCombination.id = i;
                  currentWinningCombination.averageDistance = candidateAverageDistance;
                }
              }
            }
            currentWinningCombination.id !== -TOO_HIGH_NUMBER && combinations[currentWinningCombination.id].forEach((t: any) => {
              bankTransactionGroup.addTransaction(this.r.bankStatement.getTransactions()[t.id-1])
            });
          }
          // check if there is another transaction group with the same amount
          const allOtherUnreconciledTransactions = getUnreconciledBankTransactionsOrderedByAmount(Reconciliation.db).filter((t: GetBankTransactionsOrderedByAmountReturn) => t.id !== transaction.getId());
          const nonUniqueTransactionCombinations = findCombinations4(allOtherUnreconciledTransactions, ledgerEntry.amount * -1, transaction.operationType as OperationType);
          const nonUniqueLedgerEntries = getUnreconciledLedgerEntriesWithAmount(Reconciliation.db, ledgerEntry.amount).filter((l: any) => l.id !== ledgerEntry.id);;
          if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('nonUniqueLedgerEntries', nonUniqueLedgerEntries);

          // console.log('non unique combinations', transaction.id, nonUniqueTransactionCombinations, nonUniqueLedgerEntries);
          const transactionCombinationIsUniqueBasedOnAmount = (nonUniqueTransactionCombinations.length === 0 && nonUniqueLedgerEntries.length === 0);
          state.AMOUNTS_UNIQUE = transactionCombinationIsUniqueBasedOnAmount;
          
          // console.log('MATCH NOT UNIQUE FOR THESE DATES AND AMOUNT, checking tags...', transaction.id);
          // console.log('tags', bankTransactionGroup.getTags(), this.r.ledger.getEntries()[ledgerEntry.id-1].getTags());
          const commonTermStatistics = { totalCount: 0, conflictsCount: 0 };
          const commonTermTags = this.r.getCommonTermTags(bankTransactionGroup, this.r.ledger.getEntries()[ledgerEntry.id-1]);
          state.TAGS_MATCH = commonTermTags.length > 0;
          if (state.TAGS_MATCH) {
            commonTermTags.forEach((tag: string) => {
              commonTermStatistics.totalCount++;
              const transactions = findCombinations4(allOtherTransactionsOnTheSameDate, ledgerEntry.amount * -1, transaction.operationType as OperationType);
              if (transactions) {
                let listOfTransactionIdsToCheck: number[] = [];
                transactions.forEach((row: any) => row.forEach((t: any) => listOfTransactionIdsToCheck.push(t.id)));
                listOfTransactionIdsToCheck = [...new Set(listOfTransactionIdsToCheck)];
                const conflictingTransactions = this.r.bankStatement.getTransactions().filter((t: any) => t.getStatus() === UN_RECONCILED && listOfTransactionIdsToCheck.includes(t.getId()) && t.getTags().includes(tag));
                if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('transactions', transactions, listOfTransactionIdsToCheck, conflictingTransactions);
                if (conflictingTransactions.length > 0) commonTermStatistics.conflictsCount++;
                if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('conflictingTransactions', conflictingTransactions);
              }
              const conflictingLedgerEntries = getUnreconciledLedgerEntriesWithTermTags(Reconciliation.db, tag, {date: transaction.getDate().getTime(), amount: bankTransactionGroup.getAmount()});
              if (conflictingLedgerEntries.length > 0) commonTermStatistics.conflictsCount++;
              if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('TAGS_COMBINATION_UNIQUE', commonTermStatistics);
            });
            state.TAGS_COMBINATION_UNIQUE = (commonTermStatistics.totalCount - commonTermStatistics.conflictsCount) / commonTermStatistics.totalCount > 0.5; // TODO replace 0.5 with variable
          }
          if (transactionCombinationIsUniqueBasedOnAmount && state.TAGS_MATCH) {
            this.r.reconcile(bankTransactionGroup, [ledgerEntry.id], rule);
          } else if (transactionCombinationIsUniqueBasedOnAmount || (!transactionCombinationIsUniqueBasedOnAmount && state.TAGS_MATCH && state.TAGS_COMBINATION_UNIQUE)) {
            this.r.suggest(bankTransactionGroup, [ledgerEntry.id], rule, state);
          }
          if (bankTransactionGroup.getId() === GROUP_TO_DEBUG) console.log('state', state);
        }
      }
      console.log(message, `- Reconciliations: ${reconciliations}`);
    } while (reconciliations > 0);
  }

}

export { Rule4 };