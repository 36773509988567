import { OperationType, OperationTypes } from '../common';

type Candidate = {id: number, amount: number, operationType: string, date: number, absAmount: number, bankId?: number, ledgerId?: number};

// type CandidateCombos = {
//   sum: number,
//   candidates: Candidate[],
// }[];

type CandidateCombo = {
  sum: number,
  candidates: Candidate[],
};

function* findLazyCombinations(
  candidates: Candidate[],
  target: number,
  partial: Candidate[] = [],
  partialSum: number = 0,
  startIdx: number = 0
): Generator<Candidate[]> {
  if (partialSum === target) {
    yield partial;
  } else if ((target > 0 && partialSum < target) || (target < 0 && partialSum > target)) {
    for (let i = startIdx; i < candidates.length; i++) {
      const amount = candidates[i].amount;
      const id = candidates[i].id;
      const date = candidates[i].date;
      const operationType = candidates[i].operationType;
      const absAmount = candidates[i].absAmount;

      // Add the individual candidate if its amount is equal to the target amount
      if (amount === target) {
        yield [{ amount, id, date, operationType, absAmount  }];
        continue;
      }

      // Check if the current candidate has the same date as the candidates in the partial
      if (
        partial.length === 0 ||
        partial.every((c) => c.date === date)
      ) {
        const newPartial = partial.concat([{ amount, id, date, operationType, absAmount }]);
        yield* findLazyCombinations(
          candidates,
          target,
          newPartial,
          partialSum + amount,
          i + 1
        );
      }
    }
  }
}

// function factorial(n: number): number {
//   let result = 1;
//   for (let i = 2; i <= n; i++) {
//       result *= i;
//   }
//   return result;
// }

function maxCombinationSize(n: number, threshold: number): number {
  let k = 0;
  let binom = 1;
  while (k < n && binom <= threshold) {
      k++;
      binom *= (n - k + 1) / k;
  }
  // Subtract one because the loop will exit when the binomial coefficient is too large
  return k - 1;
}

function testLimits() {
  for (let i=10; i < 100; i++) {
    const populationSize = i;
    const maxSampleSize = maxCombinationSize(populationSize, 1500000); // Adjust the threshold as necessary
    console.log(`${populationSize}, ${maxSampleSize}`);
  }
}


function findCombinations4(
  candidates: Candidate[],
  targetAmount: number,
  initialOperationType: OperationType,
): Candidate[][] {
  const result: Candidate[][] = [];
  // if (targetAmount === 0) return result;

  function find(
    currentIndex: number,
    currentSum: number,
    currentCombination: Candidate[]
  ) {
    if (currentSum === targetAmount) {
      const creditCount = currentCombination.filter(c => c.operationType === 'credit').length + (initialOperationType === OperationTypes.Credit ? 1 : 0);
      const debitCount = currentCombination.filter(c => c.operationType === 'debit').length + (initialOperationType === OperationTypes.Debit ? 1 : 0);

      if ((creditCount === 1 && debitCount === 1) || (creditCount > 0 && debitCount === 0) || (debitCount > 0 && creditCount === 0)) {
        result.push([...currentCombination]);
      }
      return;
    }

    for (let i = currentIndex; i < candidates.length; i++) {
      const candidate = candidates[i];

      // Add the individual candidate if its amount is equal to the target amount
      if (candidate.amount === targetAmount) {
        result.push([candidate]);
        continue;
      }

      // Check if the current candidate has the same date as the candidates in the currentCombination
      if (
        currentCombination.length === 0 ||
        currentCombination.every((c) => c.date === candidate.date)
      ) {
        const nextSum = Math.round((currentSum + candidate.amount) * 100) / 100;
        if ((targetAmount > 0 && nextSum <= targetAmount) || (targetAmount < 0 && nextSum >= targetAmount)) {
          currentCombination.push(candidate);
          find(i + 1, nextSum, currentCombination);
          currentCombination.pop();
        }
      }
    }
  }

  find(0, 0, []);
  return result;
}

// case many credits 

// case many debits

// case 1 credit and 1 debit

// case we start with one credit
   // case many credits or 1 credit and 1 debit
   // until we one more credit, the amounts could be anything
   // so it is better to check all the combinations of the 1 credit with the debits
   // after the 1 credit, 1 debit options are exhausted, we can check only credits 
   // that are within our range which is going to make our search space smaller
   // as we are adding credits our range is getting smaller and smaller

// case we start with one debit

// what do we do if there is no initial operation type? 
// we can check the tags individually and then only find combinations from those candidates

function* combinations(array: Candidate[], start: number = 0): Generator<Candidate[]> {
  if (start >= array.length) {
      return;
  }
  for (let i = start; i < array.length; i++) {
      const candidate: Candidate = array[i];
      yield [candidate];
      for (let subset of combinations(array, i + 1)) {
          yield [candidate, ...subset];
      }
  }
}

function* findAllCombinationsLazy(candidates: Candidate[]): Generator<CandidateCombo> {
  for (let combo of combinations(candidates)) {
      const sum = combo.reduce((acc, candidate) => acc + candidate.amount, 0);
      yield { sum, candidates: combo };
  }
}

function getLimit(n: number): number {
  // if (n <= 23) return n;
  // if (n === 24) return 10;
  // if (n === 25) return 9;
  // if (n >= 26 && n <= 28) return 8;
  // if (n >= 29 && n <= 34) return 7;
  // if (n >= 35 && n <= 46) return 6;
  // if (n >= 47 && n <= 78) return 5;
  // if (n >= 79 && n <= 100) return 4;
  // return 3; 
  if (n <= 10) return n;
  return 3; 

}

function findAllCombinations(input: Candidate[]): CandidateCombo[] {
  let result: CandidateCombo[] = [];
  const limit = getLimit(input.length);
  // console.log('limit', input.length, limit);
  const findCombinations = (arr: Candidate[], startIndex: number, data: Candidate[], index: number) => {
      if (index === data.length) {
          let sum = 0;
          let combo: Candidate[] = [];
          for (let j = 0; j < data.length; j++) {
              sum = Math.round((sum + data[j].amount)*100)/100;
              combo.push(data[j]);
          }
          result.push({ sum: sum, candidates: combo });
      } else if (startIndex >= arr.length) {
          return;
      } else if (index >= limit) { 
          return; 
      } else {
          data[index] = arr[startIndex];
          findCombinations(arr, startIndex + 1, data, index + 1);
          findCombinations(arr, startIndex + 1, data, index);
      }
  }

  // Generate all combinations for length 1 to limit
  for (let len = 1; len <= limit; len++) {
      let data: Candidate[] = new Array(len);
      findCombinations(input, 0, data, 0);
  }
  // console.log('result', result.length)
  return result;
}


// function findAllCombinations(input: Candidate[]): CandidateCombo[] {
//   let result: CandidateCombo[] = [];
//   const limit = getLimit(input.length);
//   console.log('limit', input.length, limit);
//   const findCombinations = (arr: Candidate[], startIndex: number, data: Candidate[], index: number) => {
//       if (index === data.length) {
//           let sum = 0;
//           let combo: Candidate[] = [];
//           for (let j = 0; j < data.length; j++) {
//               sum = Math.round((sum + data[j].amount)*100)/100;
//               combo.push(data[j]);
//           }
//           result.push({ sum: sum, candidates: combo });
//       } else if (startIndex >= limit) {
//           return;
//       } else {
//           data[index] = arr[startIndex];
//           findCombinations(arr, startIndex + 1, data, index + 1);
//           findCombinations(arr, startIndex + 1, data, index);
//       }
//   }

//   // Generate all combinations for length 1 to  n
//   for (let len = 1; len <= getLimit(input.length); len++) {
//       let data: Candidate[] = new Array(len);
//       findCombinations(input, 0, data, 0);
//   }
//   console.log('result', result)
//   return result;
// }

function getOneAndOneCandidates(
  candidates: Candidate[],
  targetAmount: number,
): Candidate[][] {
  const result: Candidate[][] = [];
  candidates.filter(c => c.absAmount === targetAmount).forEach(c => result.push([c]));
  return result;
}

function findMatchingCombinations(
  candidates: Candidate[],
  targetAmount: number,
  ledgerInfo: {date: number, operationType: OperationType},
): Candidate[][] {
  if (targetAmount === 4000) console.log('candidates', JSON.stringify(candidates), 'targetAmount', targetAmount, 'ledgerInfo', ledgerInfo)
  if (targetAmount === 0) throw new Error('Target amount cannot be 0');
  const { date, operationType: initialOperationType  } = ledgerInfo;
  const creditCandidates = date ? candidates.filter(c => c.operationType === 'credit' && date === c.date) : candidates.filter(c => c.operationType === 'credit');
  // console.log('creditCandidates.length', creditCandidates.length);
  const debitCandidates = date ? candidates.filter(c => c.operationType === 'debit' && date === c.date) : candidates.filter(c => c.operationType === 'debit');
  // console.log('debitCandidates.length', debitCandidates.length);

  const result: Candidate[][] = getOneAndOneCandidates(initialOperationType === OperationTypes.Credit ? debitCandidates : creditCandidates, targetAmount);
  if (initialOperationType === OperationTypes.Credit) {
    // let creditCombos: Candidate[][] = [];

    // for (let combo of findAllCombinationsLazy(creditCandidates)) {
    //   if (combo.candidates.length === 1) console.log('combo', combo);
    //   if (combo.sum === targetAmount) {
    //     creditCombos.push(combo.candidates);
    //   }
    // }
    const creditCombos = findAllCombinations(creditCandidates).filter(c => c.sum === targetAmount).map(c => c.candidates);
    // console.log('creditCandidates', creditCandidates.length, 'targetAmount', targetAmount, creditCombos);
    result.push(...creditCombos);
  } else {
    const debitCombos = findAllCombinations(debitCandidates).filter(c => c.sum === targetAmount).map(c => c.candidates);
    result.push(...debitCombos);
  }
  return result;
}

// function findAlternativeCombinations(
//   candidates: Candidate[],
//   targetAmount: number,
//   ledgerEntry: LedgerEntry,
// ): Candidate[][] {
//   const result: Candidate[][] = [];
//   for (let candidate of candidates) {
//     findMatchingCombinations(candidates, targetAmount, {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()}).forEach(c => {
//       result.push(c);
//     });
//   }
//   return result;
// }

export type { Candidate };
export { findMatchingCombinations, findCombinations4, findLazyCombinations, maxCombinationSize };