import React from 'react';
import './App.css';
import { FileDropzone } from './components/file-dropzone';
import { documents } from './ObjectFactory';

function App() {
  return (
    <>
      <header className="App App-header">
        <h1 style={{fontSize: '42px'}}>Reconcilio</h1>
      </header>
      <div className="App App-body">
        <FileDropzone documents={documents} />
        {/* <BasicTable data={data} /> */}
      </div>
      {/* <footer className="App App-footer">Copyright &copy; 2023. All rights reserved by Bitloops S.A.</footer> */}
    </>
  );
}

export default App;
