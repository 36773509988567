import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, CREDIT, DEBIT, UN_RECONCILED, TOO_HIGH_NUMBER } from '../common';
import { GetBankTransactionsOrderedByAmountReturn } from './getUnreconciledBankTransactionsOrderedByAmount';
import { BankTransaction } from '../BankTransaction';

type GetUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRangeReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange = (db: SqlJs.Database, transaction: BankTransaction, bankTransactionsOrderedByAmount: GetBankTransactionsOrderedByAmountReturn[]) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE date <> ? AND status = '${UN_RECONCILED}' AND (amount * -1) >= ? AND (amount * -1) <= ?`;
  const sumOfAllTransactionCreditAmount = bankTransactionsOrderedByAmount.filter((t: GetBankTransactionsOrderedByAmountReturn) => t.operationType === CREDIT &&transaction.getId() !== t.id).reduce((sum: number, transaction: GetBankTransactionsOrderedByAmountReturn) => sum + transaction.absAmount, 0);
  const sumOfAllTransactionDebitAmount = bankTransactionsOrderedByAmount.filter((t: GetBankTransactionsOrderedByAmountReturn) => t.operationType === DEBIT &&transaction.getId() !== t.id).reduce((sum: number, transaction: GetBankTransactionsOrderedByAmountReturn) => sum - transaction.absAmount, 0);
  let minCredit = bankTransactionsOrderedByAmount.filter((t: GetBankTransactionsOrderedByAmountReturn) => t.operationType === CREDIT &&transaction.getId() !== t.id).reduce((min: number, transaction: GetBankTransactionsOrderedByAmountReturn) => transaction.absAmount < min ? transaction.absAmount : min, TOO_HIGH_NUMBER);
  if (minCredit === TOO_HIGH_NUMBER) minCredit = 0;
  let minDebit = -bankTransactionsOrderedByAmount.filter((t: GetBankTransactionsOrderedByAmountReturn) => t.operationType === DEBIT &&transaction.getId() !== t.id).reduce((min: number, transaction: GetBankTransactionsOrderedByAmountReturn) => transaction.absAmount < min ? transaction.absAmount : min, TOO_HIGH_NUMBER);
  if (minDebit === -TOO_HIGH_NUMBER) minDebit = 0;
  const firstRangeAmount =transaction.getAccountingAmount() + (sumOfAllTransactionCreditAmount ? sumOfAllTransactionCreditAmount : minDebit);
  const secondRangeAmount =transaction.getAccountingAmount() + (sumOfAllTransactionDebitAmount ? sumOfAllTransactionDebitAmount : minCredit);
  const minRange = firstRangeAmount < secondRangeAmount ? firstRangeAmount : secondRangeAmount;
  const maxRange = firstRangeAmount > secondRangeAmount ? firstRangeAmount : secondRangeAmount;
  const date = transaction.getDate().getTime();
  const result = db.exec(SQL, [date, minRange, maxRange]);
  const results: GetUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRangeReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  // if (transaction.getId() === 3) console.log('getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange', {minRange, maxRange, results});
  return results;
}

export { getUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRange };
export type { GetUnreconciledLedgerEntriesWithDifferentDateAndSameAmountRangeReturn };