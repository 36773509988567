import React, { useEffect, useState } from 'react';
import { BankTransactionsStatement } from '../../domain/BankTransactionsStatement';
import { Ledger } from '../../domain/Ledger';
import { DifferencesItem } from './DifferencesItem';
import { LineItem } from './LineItem';
import { OperationTypes } from '../../domain/common';
import './ReconciliationReport.css';
import { LedgerEntry } from '../../domain/LedgerEntry';
import { LedgerCounterparts } from '../ledger-counterparts/LedgerCounterparts';

const CURRENCY = 'USD';

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-GR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

const roundToTwo = (num: number): number => {
  return (Math.round(num * 100)  / 100);
}

const formatAmount = (amount: number) => {
  return roundToTwo(amount).toLocaleString('en-US', { 
    style: 'currency', 
    currency: CURRENCY, 
  });
}

function ReconciliationReport (props: { bankStatement: BankTransactionsStatement, ledger: Ledger }) {
  const { bankStatement, ledger } = props;
  const [counterparts, setCounterparts] = useState<LedgerEntry[]>([]);
  const [counterpartsLocation, setCounterpartsLocation] = useState<{x: number, y: number}>({x: 0, y: 0});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  const ledgerClosingBalance = ledger.getClosingBalance();
  const ledgerUnreconciledCreditAmountSum = ledger.getUnreconciledCreditAmountSum(ledgerClosingBalance);
  const ledgerUnreconciledDebitAmountSum = ledger.getUnreconciledDebitAmountSum(ledgerUnreconciledCreditAmountSum);
  const bankStatementUnreconciledCreditTransactionsAmountSum = bankStatement.getUnreconciledCreditTransactionsAmountSum(ledgerUnreconciledDebitAmountSum);
  const bankStatementUnreconciledDebitTransactionsAmountSum = bankStatement.getUnreconciledDebitTransactionsAmountSum(bankStatementUnreconciledCreditTransactionsAmountSum);
  const bankStatementReconciledCreditTransactionsAmountSum = bankStatement.getReconciledCreditTransactionsAmountSum();
  const bankStatementReconciledDebitTransactionsAmountSum = bankStatement.getReconciledDebitTransactionsAmountSum();
  const bankStatementReconciledTransactionsAmountSum = bankStatement.getReconciledTransactionsAmountSum();
  
  const updateMousePosition = (e: MouseEvent) => {
    setMousePosition({ x: e.pageX, y: e.pageY });
  };

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);
    return () => window.removeEventListener("mousemove", updateMousePosition);
  }, []);

  return (
    <div id="reconciliation-report">
      <table style={{fontSize: '32px', fontWeight: '800', textAlign: 'center', justifyContent: 'center', margin: '0 auto'}}><tbody><tr><td>Reconciliation Report</td></tr></tbody></table>
      <table style={{fontSize: '18px', textAlign: 'left', marginBottom: '20px', fontWeight: '800'}}>
          <tbody>
            <tr>
              <td style={{width: '750px'}}>End of Period Book Balance</td>
              <td>{ledger.getClosingBalance().toLocaleString('en-US', { 
                    style: 'currency', 
                    currency: CURRENCY, 
                  })}
              </td>
              {/* <td></td>Debit balance */}
            </tr>
          </tbody>
      </table>
      <DifferencesItem title="1. Payments in our books but not present in bank" type="ledgerUnmatchedCredits" ledgerEntries={ledger.getUnreconciledCreditEntries()} />
      <LineItem title="Balance" amount={ formatAmount(ledgerUnreconciledCreditAmountSum) } typeOfBalance="" />
      <DifferencesItem title="2. Deposits wrongly posted in our books" type="ledgerUnmatchedDebits" ledgerEntries={ledger.getUnreconciledDebitEntries()} />
      <LineItem title="Balance" amount={ formatAmount(ledgerUnreconciledDebitAmountSum) } typeOfBalance="" />
      <DifferencesItem title="3. Deposits in bank but not posted in our books" type="bankUnmatchedCredits" bankStatementsTransactions={bankStatement.getUnreconciledCreditTransactions()} />
      <LineItem title="Balance" amount={ formatAmount(bankStatementUnreconciledCreditTransactionsAmountSum) } typeOfBalance="" />
      <DifferencesItem title="4. Payments in bank but not posted in our books" type="bankUnmatchedDebits" bankStatementsTransactions={bankStatement.getUnreconciledDebitTransactions()} />
      <LineItem title="Balance" amount={ formatAmount(bankStatementUnreconciledDebitTransactionsAmountSum) } typeOfBalance="" />
      {/* <LineItem title="Adjusted book balance" amount={(Math.round((Number(ledger.getOpeningBalance()) + bookBalance) * 100)/100).toLocaleString('en-US', { 
                    style: 'currency', 
                    currency: CURRENCY, 
                  })} typeOfBalance="Debit balance" />
      <LineItem title="Balance as per bank" amount={(Math.round((Number(ledger.getOpeningBalance()) + bookBalance) * 100)/100).toLocaleString('en-US', { 
                    style: 'currency', 
                    currency: CURRENCY, 
                  })} typeOfBalance="Credit balance" /> */}
      <table style={{fontSize: '18px', textAlign: 'left', marginBottom: '20px', fontWeight: '800'}}>
          <tbody>
            <tr>
              <td style={{width: '750px'}}>Bank Closing Balance</td>
              <td>{formatAmount(bankStatement.getClosingBalance())}</td>
              <td></td>
            </tr>
          </tbody>
      </table>
      <LineItem title="Difference" amount={formatAmount(bankStatement.getClosingBalance() - bankStatementUnreconciledDebitTransactionsAmountSum)} />
      <div>
      <table style={{fontSize: '28px', fontWeight: '800', textAlign: 'center', justifyContent: 'center', margin: '0 auto'}}><tbody><tr><td></td></tr></tbody></table>
      <table style={{fontSize: '28px', fontWeight: '800', textAlign: 'center', justifyContent: 'center', margin: '0 auto'}}><tbody><tr><td>Reconciled Transactions</td></tr></tbody></table>
        <table>
          <thead>
            <tr style={{fontSize: '12px', textAlign: 'left', marginBottom: '20px'}}>
              <td></td>
              <td></td>
              <td></td>
              <td>Credit</td>
              <td>Debit</td>
              <td>Ledger ID(s)</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            { bankStatement.getReconciledTransactions().map((transaction, index) => (
            <tr className={'reconciled-item-' + transaction.getStatus()} style={{fontSize: '12px', textAlign: 'left', marginBottom: '20px'}} key={index}>
              <td>{transaction.getId()}</td>
              <td>{formatDate(transaction.getDate())}</td>
              <td>{transaction.getDescription()}</td>
              <td>{transaction.getOperationType() === OperationTypes.Credit && formatAmount(transaction.getAmount()*-1)}</td>
              <td>{transaction.getOperationType() === OperationTypes.Debit && formatAmount(transaction.getAmount())}</td>
              <td>
                <div 
                  className="counterpart"
                  id={transaction.getReconciliationIds().toString()} 
                  key={transaction.getId()+'-'+transaction.getReconciliationIds().toString()}
                  onMouseOver={(event) => {
                    const ids = (event.target as HTMLInputElement).id.split(',');
                    const transactions = ids.map(id => ledger.getTransactionById(Number(id)));
                    setCounterparts(transactions);
                    setCounterpartsLocation({x: event.clientX, y: event.clientY});
                  }}
                  onMouseLeave={() => setCounterparts([])}
                  >
                {transaction.getReconciliationIds().toString()}
                </div>  
              </td>
              <td>{transaction.getStatus() === 'Suggestion' && transaction.getStatus()}</td>
            </tr>)) 
            }
            <tr style={{fontSize: '14px', textAlign: 'left', marginBottom: '20px', fontWeight: '600'}}>
              <td>Reconciled Sums</td>
              <td></td>
              <td></td>
              <td>{formatAmount(bankStatementReconciledCreditTransactionsAmountSum)}</td>
              <td>{formatAmount(bankStatementReconciledDebitTransactionsAmountSum)}</td>
            </tr>
          </tbody>
        </table>
        <LineItem title="Reconciled Balance" amount={ formatAmount(bankStatementReconciledTransactionsAmountSum) } typeOfBalance="" />
      </div>
      {counterparts.length > 0 && <LedgerCounterparts ledgerEntries={counterparts} location={counterpartsLocation} mousePosition={mousePosition} />}
    </div>
  );
}

export { ReconciliationReport }
