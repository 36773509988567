import React from 'react';
// import { BankTransaction } from '../../domain/BankTransaction';
import { BankTransaction } from '../../domain/BankTransaction';
import { LedgerEntry } from '../../domain/LedgerEntry';
import { OperationTypes } from '../../domain/common';
// import { Ledger } from '../../domain/Ledger';

const CURRENCY = 'USD';

// const getAmountType = (type: TType, side: 'left' | 'right') => {
//   if (type.includes('ledger')) {
//     if (side === 'left') return 'Debit';
//     else return 'Credit';
//   } else {
//     if (side === 'left') return 'Debit';
//     else return 'Credit';
//   }
// };

const roundToTwo = (num: number): number => {
  return (Math.round(num * 100)  / 100);
}

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-GR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

type TType = 'ledgerUnmatchedDebits' | 'ledgerUnmatchedCredits' | 'bankUnmatchedDebits' | 'bankUnmatchedCredits';

interface DifferencesItemProps {
  title: string;
  type: TType;
  bankStatementsTransactions?: BankTransaction[];
  ledgerEntries?: LedgerEntry[];
};

function formatAmount(amount: number) {
  return amount.toLocaleString('en-US', { 
    style: 'currency', 
    currency: CURRENCY, 
  });
}

function DifferencesItem (props: DifferencesItemProps) {
  const { title, type, bankStatementsTransactions, ledgerEntries } = props;
  let total = 0;
  let ledgerUnmatchedDebitsSum = 0;
  let ledgerUnmatchedCreditsSum = 0;
  let bankUnmatchedDebitsSum = 0;
  let bankUnmatchedCreditsSum = 0;

  switch (type) {
    case 'ledgerUnmatchedDebits':
      ledgerEntries && ledgerEntries.map(entry => {
        if (entry.getOperationType() === OperationTypes.Debit) {
          ledgerUnmatchedDebitsSum += entry.getAmount();
        }
        total = roundToTwo(ledgerUnmatchedDebitsSum);
        return true;
      });
      break;
    case 'ledgerUnmatchedCredits':
      ledgerEntries && ledgerEntries.map(entry => {
        if (entry.getOperationType() === OperationTypes.Credit) {
          ledgerUnmatchedCreditsSum += entry.getAmount();
        }
        total = roundToTwo(ledgerUnmatchedCreditsSum);
        return true;
      });
      break;
    case 'bankUnmatchedDebits':
      bankStatementsTransactions && bankStatementsTransactions.map(transaction => {
        if (transaction.getOperationType() === OperationTypes.Debit) {
          bankUnmatchedDebitsSum += transaction.getAmount();
        }
        total = roundToTwo(bankUnmatchedDebitsSum);
        return true;
      });
      break;
    case 'bankUnmatchedCredits':
      bankStatementsTransactions && bankStatementsTransactions.map(transaction => {
        if (transaction.getOperationType() === OperationTypes.Credit) {
          bankUnmatchedCreditsSum += transaction.getAmount();
        }
        total = roundToTwo(bankUnmatchedCreditsSum);
        return true;
      });
      break;
    default:
      break;
  }

  return (
    <div style={{fontSize: '12px', textAlign: 'left'}}>
      <table style={{fontSize: '18px', fontWeight: 'bold', marginBottom: '20px'}}><tbody><tr><td>{title}</td></tr></tbody></table>
      <div>
        { ((bankStatementsTransactions && bankStatementsTransactions.length > 0) || (ledgerEntries && ledgerEntries.length > 0)) &&
        <table>
          <thead>
            <tr>
              <th>Description</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              {/* <th style={{width: '80px'}}>{ getAmountType(type, 'left') }</th>
              <th>{ getAmountType(type, 'right') }</th> */}
            </tr>
          </thead>
          <tbody>
          {/* { bankStatementsTransactions && bankStatementsTransactions.map(item => {
            if (item.getOperationType() === OperationTypes.Debit) return (<tr key={item.getId()}><td>{`${formatDate(item.getDate())} ${item.getDescription()}`}</td><td>{formatAmount(item.getAmount())}</td><td></td></tr>);
            else return (<tr key={item.getId()}><td>{`${formatDate(item.getDate())} ${item.getDescription()}`}</td><td></td><td>{formatAmount(item.getAmount()*-1)}</td></tr>);
            }) }
          { ledgerEntries && ledgerEntries.map(item => {
            if (item.getOperationType() === OperationTypes.Debit) return (<tr key={item.getId()}><td>{`${formatDate(item.getDate())} ${item.getDescription()}`}</td><td>{formatAmount(item.getAmount())}</td><td></td></tr>);
            else return (<tr key={item.getId()}><td>{`${formatDate(item.getDate())} ${item.getDescription()}`}</td><td></td><td>{formatAmount(item.getAmount()*-1)}</td></tr>);
            }) } */}
          { ledgerEntries && ledgerEntries.map(item => (
          <tr key={item.getId()}>
            <td>{item.getId()}</td>
            <td>{formatDate(item.getDate())}</td>
            <td>{item.getDescription()}</td>
            <td>{item.getReference()}</td>
            <td>({item.getStatus()})</td>
            <td>{formatAmount(item.getOperationType() === OperationTypes.Debit ? -1*item.getAmount() : item.getAmount())}</td>
          </tr>))}
          { bankStatementsTransactions && bankStatementsTransactions.map(item => (<tr key={item.getId()}>
            <td>{item.getId()}</td>
            <td>{formatDate(item.getDate())}</td>
            <td>${item.getDescription()}</td>
            <td></td>
            <td>({item.getStatus()})</td>
            <td>{formatAmount(item.getOperationType() === OperationTypes.Debit ? -1*item.getAmount() : item.getAmount())}</td>
          </tr>)) }
          </tbody>
        </table>}
      </div>
      <table style={{fontSize: '16px', marginBottom: '20px'}}>
        <thead>
          <tr>
            <th style={{width: '720px'}}></th>
            <th style={{width: '80px'}}></th>
            <th></th>
          </tr>
        </thead>
        <tbody style={{fontWeight: '800'}}>
          { type === 'ledgerUnmatchedDebits' && (<tr><td>Total</td><td>{ formatAmount(-1*total) }</td></tr>)}
          { type === 'ledgerUnmatchedCredits' && (<tr><td>Total</td><td>{formatAmount(total) }</td></tr>)}
          { type === 'bankUnmatchedDebits' && (<tr><td>Total</td><td>{ formatAmount(-1*total) }</td></tr>)}
          { type === 'bankUnmatchedCredits' && (<tr><td>Total</td><td>{ formatAmount(total) }</td></tr>)}
        </tbody>
      </table>
    </div>
  );
}

export { DifferencesItem };
