import SqlJs from 'sql.js';
import { BANK_TRANSACTIONS_TABLE, UN_RECONCILED } from '../common';
import { BankTransaction } from '../BankTransaction';

type GetOtherUnreconciledBankTransactionsWithSameDateAndAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number, bankId: number};

const getOtherUnreconciledBankTransactionsWithSameDateAndAmount = (db: SqlJs.Database, transaction: BankTransaction) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount, bankId FROM ${BANK_TRANSACTIONS_TABLE} WHERE id <> ? AND date = ? AND status = '${UN_RECONCILED}' AND amount = ?`;
  const sameDate = transaction.getDate().getTime();
  const result = db.exec(SQL, [transaction.getId(), sameDate, transaction.getAccountingAmount()]);
  const results: GetOtherUnreconciledBankTransactionsWithSameDateAndAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4], bankId: row[5]});
  });
  return results;
}

export { getOtherUnreconciledBankTransactionsWithSameDateAndAmount };
export type { GetOtherUnreconciledBankTransactionsWithSameDateAndAmountReturn };