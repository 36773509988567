import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, UN_RECONCILED } from '../common';
import { BankTransaction } from '../BankTransaction';

type GetLedgerEntriesWithSameAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getUnreconciledLedgerEntriesWithSameAmount = (db: SqlJs.Database, transaction: BankTransaction) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE amount = ? AND status = '${UN_RECONCILED}'`;
  const result = db.exec(SQL, [-transaction.getAccountingAmount()]);
  const results: GetLedgerEntriesWithSameAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getUnreconciledLedgerEntriesWithSameAmount };
export type { GetLedgerEntriesWithSameAmountReturn };