import md5 from 'md5';

import { IDocumentsService, TDocumentDTO, TBankStatementDTO, TLedgerDTO, TDocumentTypes } from '.';

class ClientDocuments implements IDocumentsService {
  addDocument(filename: string, contents: {[sheetName: string]: string[][]}): Promise<TDocumentDTO> {
    const documentDTO: TDocumentDTO = {
      id: md5(JSON.stringify(contents)),
      name: filename,
      type: 'unknown' as 'unknown',
      contents,
    };
    console.log('addDocument', documentDTO);
    return new Promise((resolve) => resolve(documentDTO));
  }

  getSpecilizedDocument(dto: TDocumentDTO, sheetName: string): TBankStatementDTO | TLedgerDTO | undefined {
    if (dto.type === 'bank statement') {
      console.log('returning bank statement', {id: md5(JSON.stringify(dto.contents[sheetName])), name: `${dto.name} - ${sheetName}`, type: dto.type, contents: dto.contents[sheetName]});
      return {id: md5(JSON.stringify(dto.contents[sheetName])), name: `${dto.name} - ${sheetName}`, type: dto.type, contents: dto.contents[sheetName]};
    } else if (dto.type === 'ledger') {
      return {id: md5(JSON.stringify(dto.contents[sheetName])), name: `${dto.name} - ${sheetName}`, type: dto.type, contents: dto.contents[sheetName]};
    } else return undefined;
  }

  detectDocumentCategory(dto: TDocumentDTO, sheetName: string): [TDocumentTypes, TBankStatementDTO | TLedgerDTO | undefined] {
    const sheet = dto.contents[sheetName];
    if (sheet && sheet[0].filter((cell) => cell.trim() === 'Bank ID').length > 0) { // [0].toLowerCase() === 'Date'.toLowerCase()) {
      console.log('detectDocumentCategory', 'bank statement');
      const type = 'bank statement' as 'bank statement';
      return [type, {id: md5(JSON.stringify(dto.contents[sheetName])), name: `${dto.name} - ${sheetName}`, type, contents: dto.contents[sheetName]}];
    } else if (sheet && sheet && sheet[0].filter((cell) => cell.trim() === 'Ledger ID').length > 0) {
      console.log('detectDocumentCategory', 'ledger');
      const type = 'ledger' as 'ledger';
      return [type, {id: md5(JSON.stringify(dto.contents[sheetName])), name: `${dto.name} - ${sheetName}`, type, contents: dto.contents[sheetName]}];
    } else return ['unknown' as 'unknown', undefined];
  };
}

export default ClientDocuments;
