import { onlyUnique } from '../utils';
import { Lexer } from './Lexer';
import { OperationType, OperationTypes, ReconciliationStatusType, TransactionStatuses } from './common';
import { transliterate } from './reconciliation/helpers/SpecialCharacterReplace';

const fixSpreadsheetDate = (value: number): Date => {
  return new Date(Math.round((value - 25569)*86400*1000));
};

type LedgerEntryProps = {
  id: number;
  date: string | number | undefined;
  description: string;
  reference: string;
  ledgerId: string;
  debit: number | undefined;
  credit: number | undefined;
  balance: number;
};

type ConflictInfo = { id: number, type: 'Ledger Entry' | 'Bank Transaction' };

class LedgerEntry {
  // public readonly className: string = 'LedgerEntry';
  private id: number;
  private amount: number;
  private date: Date;
  private description: string;
  private ledgerId: string;
  operationType: OperationType;
  private reference: string;
  private status: ReconciliationStatusType = TransactionStatuses.Unreconciled;
  private balance: number;
  private reconciliationIds: number[] = [];
  private reconciliationStep: string | null = null;
  private conflictInfo: ConflictInfo[] = [];
  private reversedBy: number | null = null;
  private categories: string[] = [];
  private terms: string[] = [];
  private numbers: number[] = [];
  private alphanumeric: string[] = [];
  private compounds: string[] = [];
  private tags: string[] = [];

  constructor(props: LedgerEntryProps) {
    const { id, date, description, reference, ledgerId, credit, debit, balance } = props;
    this.id = id;
    if (!credit && !debit) throw new Error('Either credit or debit must be defined');
    if (credit && debit) throw new Error('Either credit or debit must be defined, not both');
    if (credit) {
      this.operationType = OperationTypes.Credit;
      this.amount = credit;
    } else {
      this.operationType = OperationTypes.Debit;
      if (debit) this.amount = debit;
    }
    this.amount = props.credit ? props.credit : (props.debit ? props.debit : 0);
    this.amount = Math.round((this.amount + Number.EPSILON) * 100) / 100;
    this.description = transliterate(description.toLowerCase());
    this.reference = transliterate(reference.toLowerCase());
    this.ledgerId = ledgerId;
    if (date) {
      if (typeof date === 'number') {
        this.date = fixSpreadsheetDate(date);
        this.date = new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()));
      } else {
        const dateParts = date.split('/');
        if (dateParts.length !== 3) throw new Error('Date must be in format dd/mm/yyyy');
        if (Number(dateParts[1]) > 12) throw new Error('Date must be in format dd/mm/yyyy');
        this.date = new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]} 00:00:00 GMT`);
      }
    } else {
      throw new Error('Date is required and must be defined');
    }
    const descriptionLexer = new Lexer(this.description);
    const referenceLexer = new Lexer(this.reference);
    this.categories = [...descriptionLexer.getCategories(), ...referenceLexer.getCategories()].filter(onlyUnique);
    this.terms = [...descriptionLexer.getTerms(), ...referenceLexer.getTerms()].filter(onlyUnique);
    this.numbers = [...descriptionLexer.getNumbers(), ...referenceLexer.getNumbers()].filter(onlyUnique);
    this.alphanumeric = [...descriptionLexer.getAlphanumeric(), ...referenceLexer.getAlphanumeric()].filter(onlyUnique);
    this.compounds = [...descriptionLexer.getCompounds(), ...referenceLexer.getCompounds()].filter(onlyUnique);
    this.tags = [...this.terms, ...this.compounds, ...this.alphanumeric];
    
    this.balance = balance;
  }

  public static create(props: LedgerEntryProps): LedgerEntry {
    return new LedgerEntry(props);
  }

  public static createFromArray(headers: string[], rowData: string[], rowIndex: number): LedgerEntry {
    const ledgerEntryProps: LedgerEntryProps = {
      id: rowIndex,
      date: rowData[headers.indexOf('Date')],
      description: String(rowData[headers.indexOf('Description')]),
      reference: String(rowData[headers.indexOf('Reference')]),
      ledgerId: String(rowData[headers.indexOf('Ledger ID')]),
      debit: rowData[headers.indexOf('Debit')] ? Number(rowData[headers.indexOf('Debit')]) : undefined,
      credit: rowData[headers.indexOf('Credit')] ? Number(rowData[headers.indexOf('Credit')]) : undefined,
      balance: headers.indexOf('Balance') === -1 ? 0 : Number(rowData[headers.indexOf('Balance')]),
    };
    return new LedgerEntry(ledgerEntryProps);
  }

  getAlphanumerics() {
    return this.alphanumeric;
  }

  getCategories() {
    return this.categories;
  }

  getTags() {
    return this.tags;
  }

  getTerms() {
    return this.terms;
  }

  getNumbers() {
    return this.numbers;
  }

  getCompounds() {
    return this.compounds;
  }

  getId() {
    return this.id;
  }

  getOperationType() {
    return this.operationType;
  }

  getStatus() {
    return this.status;
  }

  getDescription() {
    return this.description;
  }

  getAmount() {
    return this.amount;
  }

  getAccountingAmount() {
    return this.operationType === OperationTypes.Credit ? this.amount : -this.amount;
  } 

  getConflictInfo() {
    return this.conflictInfo;
  }

  getDate() {
    return this.date;
  }

  getBalance() {
    return this.balance;
  }

  getReference() {
    return this.reference;
  }

  getLedgerId() {
    return this.ledgerId;
  }

  reconcile(ids: number[], step: string) {
    // if (this.id === 4) console.log('reconciling entry 4', ids, step);
    this.status = TransactionStatuses.Reconciled;
    this.reconciliationIds = ids;
    this.reconciliationStep = step;
  }

  suggest(ids: number[], step: string) {
    // if (this.id === 4) console.log('suggesting entry 4', ids, step);
    this.status = TransactionStatuses.Suggestion;
    this.reconciliationIds = ids;
    this.reconciliationStep = step;
  }

  declareConflict(conflictInfo: ConflictInfo[], step: string) {
    this.status = TransactionStatuses.Conflicting;
    this.conflictInfo = conflictInfo;
    this.reconciliationStep = step;
  }

  reverse(id: number) {
    this.status = TransactionStatuses.Reversed;
    this.reversedBy = id;
  }

  // toArray() {
  //   return [this.date.toLocaleDateString(), this.description, this.amount, this.currencyCode, this.status];
  // }

  // toString() {
  //   return `${this.date.toLocaleDateString()} ${this.amount} ${this.currencyCode} ${this.description} ${this.status}`;
  // }
}

export { LedgerEntry };