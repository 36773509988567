import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, UN_RECONCILED } from '../common';
import { BankTransaction } from '../BankTransaction';
import { LedgerEntry } from '../LedgerEntry';

type GetOtherLedgerEntriesWithSameDateReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getOtherUnreconciledLedgerEntriesWithSameDate = (db: SqlJs.Database, transaction: BankTransaction, ledgerEntry: LedgerEntry, forceSameLedgerId?: boolean) => {
  let SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE id <> ? AND date = ? AND date = ? AND status = '${UN_RECONCILED}'`;
  const transactionDate = transaction.getDate().getTime();
  const ledgerEntryDate = ledgerEntry.getDate().getTime();
  let params: any = [ledgerEntry.getId(), transactionDate, ledgerEntryDate];
  if (forceSameLedgerId !== undefined) {
    SQL += ' AND date = ?' + (forceSameLedgerId ? ' AND ledgerId = ? AND ledgerId <> \'\'' : '');
    params = forceSameLedgerId ? [ledgerEntry.getId(), transactionDate, ledgerEntryDate, ledgerEntry.getLedgerId()] : [ledgerEntry.getId(), ledgerEntry.getDate().getTime()];
  }
  // if (transactionDate !== ledgerEntryDate) throw new Error('Transaction and ledger entry dates must be the same');
  // if (transaction.getId() === 3 && ledgerEntry.getId() === 11) console.log('transactionDate', transactionDate, 'ledgerEntryDate', ledgerEntryDate, SQL);
  const result = db.exec(SQL, params);
  const results: GetOtherLedgerEntriesWithSameDateReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getOtherUnreconciledLedgerEntriesWithSameDate };
export type { GetOtherLedgerEntriesWithSameDateReturn };