import { onlyUnique } from '../utils';
import { Ledger } from './Ledger';
import { LedgerEntry } from './LedgerEntry';
import { OperationTypes, TransactionStatuses } from './common';

type LedgerTransactionGroupType = 'One Credit' | 'One Debit' | 'All Credit' | 'One Credit and One Debit' | 'All Debit';
export const LedgerTransactionGroupTypes = {
  OneCredit: 'One Credit' as LedgerTransactionGroupType,
  OneDebit: 'One Debit' as LedgerTransactionGroupType,
  AllCredit: 'All Credit' as LedgerTransactionGroupType,
  OneCreditOneDebit: 'One Credit and One Debit' as LedgerTransactionGroupType,
  AllDebit: 'All Debit' as LedgerTransactionGroupType,
};

class LedgerTransactionGroup {
  private id: string | null = null;
  private allTransactions: LedgerEntry[] = [];
  private firstId: number | null = null;
  private amount: number = 0;
  private date: Date | null = null;
  private transactions: LedgerEntry[] = [];
  private alphanumerics: string[] = [];
  private tags: string[] = [];
  private categories: string[] = [];
  private terms: string[] = [];
  private numbers: number[] = [];
  private compounds: string[] = [];
  private reconciliationIds: number[] = [];
  private ledgerId: string | null = null;
  private reconciliationStep: string | null = null;
  private type: LedgerTransactionGroupType | null = null;

  constructor(private ledger: Ledger) {}

  getClassName() {
    return 'LedgerTransactionGroup';
  }

  addTransaction(transaction: LedgerEntry) {
    if (!this.firstId) {
      this.firstId = transaction.getId();
      this.date = transaction.getDate();
      this.ledgerId = transaction.getLedgerId();
      this.id = transaction.getId().toString();
      this.type = transaction.getOperationType() === OperationTypes.Credit ? LedgerTransactionGroupTypes.OneCredit : LedgerTransactionGroupTypes.OneDebit;
    } else {
      if (this.date?.getTime() !== transaction.getDate().getTime()) throw new Error('Transactions must have the same date '+this.getId()+' '+transaction.getId());
      if (this.ledgerId !== transaction.getLedgerId()) this.ledgerId = null; // this checks if they have the same ledgerId
      if (this.type === LedgerTransactionGroupTypes.OneCreditOneDebit) throw new Error('Only two transactions of different operation type are allowed');
      if (this.type === LedgerTransactionGroupTypes.AllCredit && transaction.getOperationType() === OperationTypes.Debit) {
        throw new Error('Only two transactions of different operation type are allowed');
      } else if (this.type === LedgerTransactionGroupTypes.AllDebit && transaction.getOperationType() === OperationTypes.Credit) {
        throw new Error('Only two transactions of different operation type are allowed');
      }
      if (this.type === LedgerTransactionGroupTypes.OneCredit && transaction.getOperationType() === OperationTypes.Debit) {
        this.type = LedgerTransactionGroupTypes.OneCreditOneDebit;
      } else if (this.type === LedgerTransactionGroupTypes.OneDebit && transaction.getOperationType() === OperationTypes.Credit) {
        this.type = LedgerTransactionGroupTypes.OneCreditOneDebit;
      } else if (this.type === LedgerTransactionGroupTypes.OneCredit && transaction.getOperationType() === OperationTypes.Credit) {
        this.type = LedgerTransactionGroupTypes.AllCredit;
      } else if (this.type === LedgerTransactionGroupTypes.OneDebit && transaction.getOperationType() === OperationTypes.Debit) {
        this.type = LedgerTransactionGroupTypes.AllDebit;
      }
      this.id = `${this.id}+${transaction.getId().toString()}`;
    }
    this.transactions.push(transaction);
    this.alphanumerics = [...this.alphanumerics, ...transaction.getAlphanumerics()].filter(onlyUnique);
    this.tags = [...this.tags, ...transaction.getTags()].filter(onlyUnique);
    this.categories = [...this.categories, ...transaction.getCategories()].filter(onlyUnique);
    this.terms = [...this.terms, ...transaction.getTerms()].filter(onlyUnique);
    this.numbers = [...this.numbers, ...transaction.getNumbers()].filter(onlyUnique);
    this.compounds = [...this.compounds, ...transaction.getCompounds()].filter(onlyUnique);
    this.amount = transaction.operationType === OperationTypes.Credit ? this.amount + transaction.getAmount() : this.amount - transaction.getAmount();
  }

  copy() {
    const copy = new LedgerTransactionGroup(this.ledger);
    copy.id = this.id;
    copy.firstId = this.firstId;
    copy.amount = this.amount;
    copy.date = this.date; 
    copy.transactions = this.transactions.map((t) => t);
    copy.alphanumerics = this.alphanumerics; 
    copy.tags = this.tags;
    copy.categories = this.categories;
    copy.terms = this.terms;
    copy.numbers = this.numbers; 
    copy.compounds = this.compounds; 
    copy.reconciliationIds = this.reconciliationIds; 
    copy.ledgerId = this.ledgerId; 
    copy.reconciliationStep = this.reconciliationStep; 
    copy.type = this.type;
    return copy;
  }

  getId() {
    return this.id;
  }

  getIds() {
    return this.id?.split('+').map((id) => parseInt(id)) ?? [];
  }

  getTransactions() {
    return this.transactions;
  }

  getAlphanumerics() {
    return this.alphanumerics;
  }

  getTags() {
    return this.tags;
  }

  getCategories() {
    return this.categories;
  }

  getTerms() {
    return this.terms;
  }

  getNumbers() {
    return this.numbers;
  }

  getCompounds() {
    return this.compounds;
  }

  size = () => this.transactions.length;

  getAmount() {
    return this.amount;
  }

  getStatus() {
    if (this.transactions.length > 1) {
      if (this.transactions.some((transaction) => transaction.getStatus() === TransactionStatuses.Reconciled)) {
        return TransactionStatuses.Reconciled;
      } else if (this.transactions.some((transaction) => transaction.getStatus() === TransactionStatuses.Suggestion)) {
        return TransactionStatuses.Suggestion;
      } else return TransactionStatuses.Unreconciled;
    } else if (this.transactions.length === 1) {
      return this.ledger.getEntries()[this.transactions[0].getId()-1].getStatus();
    } else return TransactionStatuses.Unreconciled;
  }

  getDate() {
    return this.date;
  }

  getLedgerId() {
    return this.ledgerId;
  }

  getType() {
    return this.type;
  }

  setType(type: LedgerTransactionGroupType) {
    this.type = type;
  }

  reconcile(ids: number[], step: string) {
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
    this.transactions.forEach((transaction) => {
      this.ledger.getEntries()[transaction.getId() - 1].reconcile(ids, step);
    });
  }

  suggest(ids: number[], step: string) {
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
    this.transactions.forEach((transaction) => {
      this.ledger.getEntries()[transaction.getId() - 1].suggest(ids, step);
    });
  }

}

export { LedgerTransactionGroup };