import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, UN_RECONCILED } from '../common';

type GetUnreconciledLedgerEntriesWithAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getUnreconciledLedgerEntriesWithAmount = (db: SqlJs.Database, amount: number) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE status = '${UN_RECONCILED}' AND amount = ?`;
  const result = db.exec(SQL, [amount]);
  const results: GetUnreconciledLedgerEntriesWithAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getUnreconciledLedgerEntriesWithAmount };
export type { GetUnreconciledLedgerEntriesWithAmountReturn };