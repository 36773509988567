import React from 'react';
import { LedgerEntry } from '../../domain/LedgerEntry';
import { OperationTypes } from '../../domain/common';

const CURRENCY = 'USD';

const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-GR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

const roundToTwo = (num: number): number => {
  return (Math.round(num * 100)  / 100);
}

const formatAmount = (amount: number) => {
  return roundToTwo(amount).toLocaleString('en-US', { 
    style: 'currency', 
    currency: CURRENCY, 
  });
}

function LedgerCounterparts (props: { ledgerEntries: LedgerEntry[], location: {x: number, y: number}, mousePosition: {x: number, y: number} }) {
  const { mousePosition } = props;
  
  return (
    <div style={
      {
        border: '1px solid black',
        position: 'absolute',
        minHeight: '200px',
        width: '800px',
        top: mousePosition.y - 220,
        left: mousePosition.x - 600,
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      }
    }>
      <table style={{fontSize: '18px', textAlign: 'left', marginBottom: '20px'}}>
        <tbody>
          <tr style={{fontWeight: '800', padding: '10px', alignContent: 'center', alignItems: 'center'}}>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>ID</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Date</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Description</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Reference</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Ledger ID</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Debit</td>
            <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>Credit</td>
          </tr>
              { props.ledgerEntries.map((ledgerEntry) => (
              
                <tr key={ledgerEntry.getId()} style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getId()}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{formatDate(ledgerEntry.getDate())}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getDescription()}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getReference()}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getLedgerId()}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getOperationType() === OperationTypes.Debit && formatAmount(ledgerEntry.getAmount())}</td>
                  <td style={{alignContent: 'center', alignItems: 'center', padding: '10px'}}>{ledgerEntry.getOperationType() === OperationTypes.Credit && formatAmount(ledgerEntry.getAmount())}</td>
                </tr>
            )) }
        </tbody>
      </table>
    </div>
  );
}

export { LedgerCounterparts };
