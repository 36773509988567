import { onlyUnique } from '../utils';
import { BankTransaction } from './BankTransaction';
import { BankTransactionsStatement } from './BankTransactionsStatement';
import { OperationTypes, TransactionStatuses } from './common';

type BankTransactionGroupType = 'One Credit' | 'One Debit' | 'All Credit' | 'One Credit and One Debit' | 'All Debit';
export const BankTransactionGroupTypes = {
  OneCredit: 'One Credit' as BankTransactionGroupType,
  OneDebit: 'One Debit' as BankTransactionGroupType,
  AllCredit: 'All Credit' as BankTransactionGroupType,
  OneCreditOneDebit: 'One Credit and One Debit' as BankTransactionGroupType,
  AllDebit: 'All Debit' as BankTransactionGroupType,
};

class BankTransactionGroup {
  private id: string | null = null;
  private allTransactions: BankTransaction[] = [];
  private firstId: number | null = null;
  private amount: number = 0;
  private date: Date | null = null;
  private transactions: BankTransaction[] = [];
  private alphanumerics: string[] = [];
  private tags: string[] = [];
  private categories: string[] = [];
  private terms: string[] = [];
  private numbers: number[] = [];
  private compounds: string[] = [];
  private reconciliationIds: number[] = [];
  private bankId: string | null = null;
  private reconciliationStep: string | null = null;
  private type: BankTransactionGroupType | null = null;

  constructor(private bankStatement: BankTransactionsStatement) {}

  getClassName() {
    return 'BankTransactionGroup';
  }

  addTransaction(transaction: BankTransaction) {
    if (!this.firstId) {
      this.firstId = transaction.getId();
      this.date = transaction.getDate();
      this.bankId = transaction.getBankId();
      this.id = transaction.getId().toString();
      this.type = transaction.getOperationType() === OperationTypes.Credit ? BankTransactionGroupTypes.OneCredit : BankTransactionGroupTypes.OneDebit;
    } else {
      if (this.date?.getTime() !== transaction.getDate().getTime()) throw new Error('Transactions must have the same date');
      if (this.bankId !== transaction.getBankId()) this.bankId = null; // this checks if they have the same bankId
      if (this.type === BankTransactionGroupTypes.OneCreditOneDebit) throw new Error('Only two transactions of different operation type are allowed');
      if (this.type === BankTransactionGroupTypes.AllCredit && transaction.getOperationType() === OperationTypes.Debit) {
        throw new Error('Only two transactions of different operation type are allowed');
      } else if (this.type === BankTransactionGroupTypes.AllDebit && transaction.getOperationType() === OperationTypes.Credit) {
        throw new Error('Only two transactions of different operation type are allowed');
      }
      if (this.type === BankTransactionGroupTypes.OneCredit && transaction.getOperationType() === OperationTypes.Debit) {
        this.type = BankTransactionGroupTypes.OneCreditOneDebit;
      } else if (this.type === BankTransactionGroupTypes.OneDebit && transaction.getOperationType() === OperationTypes.Credit) {
        this.type = BankTransactionGroupTypes.OneCreditOneDebit;
      } else if (this.type === BankTransactionGroupTypes.OneCredit && transaction.getOperationType() === OperationTypes.Credit) {
        this.type = BankTransactionGroupTypes.AllCredit;
      } else if (this.type === BankTransactionGroupTypes.OneDebit && transaction.getOperationType() === OperationTypes.Debit) {
        this.type = BankTransactionGroupTypes.AllDebit;
      }
      this.id = `${this.id}+${transaction.getId().toString()}`;
    }
    this.transactions.push(transaction);
    this.alphanumerics = [...this.alphanumerics, ...transaction.getAlphanumerics()].filter(onlyUnique);
    this.tags = [...this.tags, ...transaction.getTags()].filter(onlyUnique);
    this.categories = [...this.categories, ...transaction.getCategories()].filter(onlyUnique);
    this.terms = [...this.terms, ...transaction.getTerms()].filter(onlyUnique);
    this.numbers = [...this.numbers, ...transaction.getNumbers()].filter(onlyUnique);
    this.compounds = [...this.compounds, ...transaction.getCompounds()].filter(onlyUnique);
    this.amount = transaction.operationType === OperationTypes.Credit ? this.amount + transaction.getAmount() : this.amount - transaction.getAmount();
  }

  copy() {
    const copy = new BankTransactionGroup(this.bankStatement);
    copy.id = this.id;
    copy.firstId = this.firstId;
    copy.amount = this.amount;
    copy.date = this.date; 
    copy.transactions = this.transactions.map((t) => t);
    copy.alphanumerics = this.alphanumerics; 
    copy.tags = this.tags;
    copy.categories = this.categories;
    copy.terms = this.terms;
    copy.numbers = this.numbers; 
    copy.compounds = this.compounds; 
    copy.reconciliationIds = this.reconciliationIds; 
    copy.bankId = this.bankId; 
    copy.reconciliationStep = this.reconciliationStep; 
    copy.type = this.type;
    return copy;
  }

  getId() {
    return this.id;
  }

  getIds() {
    return this.id?.split('+').map((id) => parseInt(id)) || [];
  }

  getTransactions() {
    return this.transactions;
  }

  getAlphanumerics() {
    return this.alphanumerics;
  }

  getTags() {
    return this.tags;
  }

  getCategories() {
    return this.categories;
  }

  getTerms() {
    return this.terms;
  }

  getNumbers() {
    return this.numbers;
  }

  getCompounds() {
    return this.compounds;
  }

  size = () => this.transactions.length;

  getAmount() {
    return this.amount;
  }

  getStatus() {
    if (this.transactions.length > 1) {
      if (this.transactions.some((transaction) => transaction.getStatus() === TransactionStatuses.Reconciled)) {
        return TransactionStatuses.Reconciled;
      } else if (this.transactions.some((transaction) => transaction.getStatus() === TransactionStatuses.Suggestion)) {
        return TransactionStatuses.Suggestion;
      } else return TransactionStatuses.Unreconciled;
    } else if (this.transactions.length === 1) {
      return this.bankStatement.getTransactions()[this.transactions[0].getId()-1].getStatus();
    } else return TransactionStatuses.Unreconciled;
  }

  getDate() {
    return this.date;
  }

  getBankId() {
    return this.bankId;
  }

  getType() {
    return this.type;
  }

  setType(type: BankTransactionGroupType) {
    this.type = type;
  }

  reconcile(ids: number[], step: string) {
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
    this.transactions.forEach((transaction) => {
      this.bankStatement.getTransactions()[transaction.getId() - 1].reconcile(ids, step);
    });
  }

  suggest(ids: number[], step: string) {
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
    this.transactions.forEach((transaction) => {
      this.bankStatement.getTransactions()[transaction.getId() - 1].suggest(ids, step);
    });
  }

}

export { BankTransactionGroup };