import { onlyUnique } from '../utils';
import { Lexer } from './Lexer';
import { ConflictInfoType, OperationType, OperationTypes, ReconciliationStatusType, TransactionStatuses } from './common';
import { transliterate } from './reconciliation/helpers/SpecialCharacterReplace';

const fixSpreadsheetDate = (value: any): Date => {
  return new Date(Math.round((value - 25569)*86400*1000));
};

type BankTransactionProps = {
  id: number;
  date: string | number;
  description: string;
  bankId: string;
  reference: string;
  debit: number | undefined;
  credit: number | undefined;
  balance: number;
  // operationType: 'credit' | 'debit';
  // currencyCode: string;
};

class BankTransaction {
  // public readonly className: string = 'BankTransaction';
  private id: number;
  private isReversal: boolean = false;
  private amount: number;
  private date: Date;
  private description: string;
  // private reversalId: number | null = null;
  private reconciliationIds: number[] = [];
  private conflictInfo: ConflictInfoType[] = [];
  private reconciliationStep: string | null = null;
  operationType: OperationType;
  private status: ReconciliationStatusType = TransactionStatuses.Unreconciled;
  // private reversalId: string | null;
  private bankId: string;
  private balance: number;
  private reference: string;
  private alphanumeric: string[] = [];
  private categories: string[] = [];
  private terms: string[] = [];
  private numbers: number[] = [];
  private compounds: string[] = [];
  private tags: string[] = [];

  private constructor(props: BankTransactionProps) {
    const { id, date, description, reference, bankId, credit, debit, balance } = props;
    this.id = id;
    this.reference = transliterate(reference.toLowerCase()) || '';
    this.bankId = bankId || '';
    if (!credit && !debit) {
      throw new Error('Either credit or debit must be defined' + JSON.stringify(props));
    }
    if (credit && debit) throw new Error('Either credit or debit must be defined, not both');
    if (credit) {
      this.operationType = OperationTypes.Credit;
      this.amount = credit;
    } else {
      this.operationType = OperationTypes.Debit;
      if (debit) this.amount = debit;
    }
    // super(props, props.id);
    this.amount = props.credit ? props.credit : (props.debit ? props.debit : 0);
    this.amount = Math.round((this.amount + Number.EPSILON) * 100) / 100;
    this.description = transliterate(description.toLowerCase()) || '';
    if (typeof date === 'number') {
      this.date = fixSpreadsheetDate(date);
      this.date = new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()));
    }
    else {
      const dateParts = date.split('/');
      if (dateParts.length !== 3) throw new Error('Date must be in format dd/mm/yyyy');
      if (Number(dateParts[1]) > 12) throw new Error('Date must be in format dd/mm/yyyy');
      this.date = new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]} 00:00:00 GMT`);
    }
    const descriptionLexer = new Lexer(this.description);
    const referenceLexer = new Lexer(this.reference);
    this.categories = [...descriptionLexer.getCategories(), ...referenceLexer.getCategories()].filter(onlyUnique);
    this.terms = [...descriptionLexer.getTerms(), ...referenceLexer.getTerms()];
    this.numbers = [...descriptionLexer.getNumbers(), ...referenceLexer.getNumbers()].filter(onlyUnique);
    this.alphanumeric = [...descriptionLexer.getAlphanumeric(), ...referenceLexer.getAlphanumeric()];
    this.compounds = [...descriptionLexer.getCompounds(), ...referenceLexer.getCompounds()].filter(onlyUnique);
    this.tags = [...this.terms, ...this.compounds, ...this.alphanumeric];
    
    this.balance = balance;
  }

  public static create(props: BankTransactionProps): BankTransaction {
    return new BankTransaction(props);
  }

  public static createFromArray(headers: string[], rowData: string[], rowIndex: number): BankTransaction {
    const bankTransactionProps: BankTransactionProps = {
      id: rowIndex,
      date: rowData[headers.indexOf('Date')],
      description: String(rowData[headers.indexOf('Description')]),
      reference: String(rowData[headers.indexOf('Reference')]),
      bankId: String(rowData[headers.indexOf('Bank ID')]),
      debit: rowData[headers.indexOf('Debit')] ? Number(rowData[headers.indexOf('Debit')]) : undefined,
      credit: rowData[headers.indexOf('Credit')] ? Number(rowData[headers.indexOf('Credit')]) : undefined,
      balance: headers.indexOf('Balance') === -1 ? 0 : Number(rowData[headers.indexOf('Balance')]),
    };
    return new BankTransaction(bankTransactionProps);
  }

  getClassName() {
    return 'BankTransaction';
  }

  getAlphanumerics() {
    return this.alphanumeric;
  }

  getCategories() {
    return this.categories;
  }

  getTags() {
    return this.tags;
  }

  getTerms() {
    return this.terms;
  }

  getNumbers() {
    return this.numbers;
  }

  getCompounds() {
    return this.compounds;
  }

  getId() {
    return this.id;
  }

  getBalance() {
    return this.balance;
  }

  getConflictInfo() {
    return this.conflictInfo;
  }

  getOperationType() {
    return this.operationType;
  }

  getStatus() {
    return this.status;
  }

  getDescription() {
    return this.description;
  }

  getAmount() {
    return this.amount;
  }

  getAccountingAmount() {
    return this.operationType === OperationTypes.Credit ? this.amount : -this.amount;
  } 

  getDate() {
    return this.date;
  }

  getReference() {
    // if (!this.reference) throw new Error('Reference is not defined');
    return this.reference;
  }

  getBankId() {
    return this.bankId;
  }

  getIsReversal(): boolean {
    return this.isReversal;
  }

  getReconciliationIds(): number[] {
    return this.reconciliationIds;
  }

  // getReversalId(): string | null {
  //   return this.reversalId;
  // }

  // reverse(id: number) {
  //   this.status = 'Reversed';
  //   this.reversalId = id;
  // }

  reconcile(ids: number[], step: string) {
    this.status = TransactionStatuses.Reconciled;
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
  }

  unreconcile() {
    this.status = TransactionStatuses.Unreconciled;
    this.reconciliationIds = [];
  }

  suggest(ids: number[], step: string) {
    this.status = TransactionStatuses.Suggestion;
    this.reconciliationStep = step;
    this.reconciliationIds = ids;
  }

  declareConflict(conflictInfo: ConflictInfoType[], step: string) {
    this.status = TransactionStatuses.Conflicting;
    this.reconciliationStep = step;
    this.conflictInfo = conflictInfo;
  }


  // toArray() {
  //   return [this.date.toLocaleDateString(), this.description, this.amount, this.currencyCode, this.status];
  // }

  // toString() {
  //   return `${this.date.toLocaleDateString()} ${this.amount} ${this.currencyCode} ${this.description} ${this.status}`;
  // }
}

export { BankTransaction };