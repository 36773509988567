import { TLedgerDTO } from '../infra/services/docs';
import { LedgerEntry } from './LedgerEntry';
import { NonReconciledStatuses, OperationTypes, ReconciledStatuses, TransactionStatuses } from './common';

const BANK_CHARGE = 'bankCharge';
const REVERSAL = 'reversal';

class Ledger {
  private id: string;
  private name: string;
  // private contentsArray: string[][];
  private ledgerEntries: LedgerEntry[];
  private bankCharges: LedgerEntry[];
  private headers: string[];

  constructor(ledgerDTO: TLedgerDTO) {
    if (ledgerDTO.type !== 'ledger') throw new Error('Invalid type provided!');
    if (!ledgerDTO.contents) throw new Error('No ledger contents found!');
    if (ledgerDTO.contents.length < 2) throw new Error('Invalid number of ledger rows!');
    this.id = ledgerDTO.id;
    this.name = ledgerDTO.name;
    // this.contentsArray = bankStatement.contents;
    // this.headers = bankStatement.contents[0];
    this.ledgerEntries = [];
    this.bankCharges = [];
    this.headers = ledgerDTO.contents[0];
    for (let i = 1; i < ledgerDTO.contents.length; i++) {
      const row = ledgerDTO.contents[i];
      if (row.filter((cell) => cell !== '').length > 0) {
        const ledgerEntry = LedgerEntry.createFromArray(this.headers, row, i);
        if (ledgerEntry.getCategories().includes(BANK_CHARGE)) {
          this.bankCharges.push(ledgerEntry);
        } else {
          this.ledgerEntries.push(ledgerEntry);
        }
      }
    }
  }

  reconcile(ledgerEntries: LedgerEntry[], counterPartIds: number[], step: string) {
    ledgerEntries.forEach((entry: LedgerEntry) => {
      this.ledgerEntries[entry.getId()-1].reconcile(counterPartIds, step);
    });
  }

  suggest(ledgerEntries: LedgerEntry[], counterPartIds: number[], step: string) {
    ledgerEntries.forEach((entry: LedgerEntry) => {
      this.ledgerEntries[entry.getId()-1].suggest(counterPartIds, step);
    });
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getOpeningBalance() {
    const firstEntry = this.ledgerEntries[0];
    if (firstEntry.getOperationType() === OperationTypes.Credit) return firstEntry.getBalance() - firstEntry.getAmount();
    return firstEntry.getBalance() + firstEntry.getAmount();
  }

  getClosingBalance() {
    const lastEntry = this.ledgerEntries[this.ledgerEntries.length - 1];
    return lastEntry.getBalance();
  }

  getEntries() {
    return this.ledgerEntries;
  }

  getTransactionById(id: number) {
    return this.ledgerEntries[id - 1];
  }

  getBankCharges() {
    return this.bankCharges;
  }

  getReconciledEntries() {
    const bankCharges = this.bankCharges.filter((entry) => ReconciledStatuses.includes(entry.getStatus()));
    const otherEntries = this.ledgerEntries.filter((entry) => ReconciledStatuses.includes(entry.getStatus()));
    return otherEntries.concat(bankCharges);
  }

  getReconciledCreditEntries() {
    const bankCharges = this.bankCharges.filter((entry) => ReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    const otherEntries = this.ledgerEntries.filter((entry) => ReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    return otherEntries.concat(bankCharges);
  }

  getReconciledDebitEntries() {
    const bankCharges = this.bankCharges.filter((entry) => ReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    const otherEntries = this.ledgerEntries.filter((entry) => ReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    return otherEntries.concat(bankCharges);
  }

  getUnreconciledDebitEntries() {
    const bankCharges = this.bankCharges.filter((entry) => [TransactionStatuses.Unreconciled].includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    const otherEntries = this.ledgerEntries.filter((entry) => [TransactionStatuses.Unreconciled].includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    return otherEntries.concat(bankCharges);
  }

  getNonReconciledEntries() {
    const bankCharges = this.bankCharges.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()));
    const otherEntries = this.ledgerEntries.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()));
    return otherEntries.concat(bankCharges);
  }

  getNonReconciledCreditEntries() {
    const bankCharges = this.bankCharges.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    const otherEntries = this.ledgerEntries.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    return otherEntries.concat(bankCharges);
  }

  getUnreconciledCreditEntries() {
    const bankCharges = this.bankCharges.filter((entry) => [TransactionStatuses.Unreconciled].includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    const otherEntries = this.ledgerEntries.filter((entry) => [TransactionStatuses.Unreconciled].includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Credit);
    return otherEntries.concat(bankCharges);
  }

  getNonReconciledDebitEntries() {
    const bankCharges = this.bankCharges.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    const otherEntries = this.ledgerEntries.filter((entry) => NonReconciledStatuses.includes(entry.getStatus()) && entry.getOperationType() === OperationTypes.Debit);
    return otherEntries.concat(bankCharges);
  }

  getReconciledAmountSum(initAmount = 0) {
    return this.getReconciledEntries().reduce((acc, entry) => {
      if (entry.getOperationType() === OperationTypes.Credit) return acc - entry.getAmount();
      return acc + entry.getAmount();
    }, initAmount);
  }

  getUnreconciledAmountSum(initAmount = 0) {
    return this.getNonReconciledEntries().reduce((acc, entry) => {
      if (entry.getOperationType() === OperationTypes.Credit) return acc - entry.getAmount();
      return acc + entry.getAmount();
    }, initAmount);
  }

  getReconciledCreditAmountSum(initAmount = 0) {
    return this.getReconciledCreditEntries().reduce((acc, entry) => acc + entry.getAmount(), initAmount);
  }

  getNonReconciledCreditAmountSum(initAmount = 0) {
    return this.getNonReconciledCreditEntries().reduce((acc, entry) => { 
      return acc + entry.getAmount();
    }, initAmount);
  }

  getUnreconciledCreditAmountSum(initAmount = 0) {
    return this.ledgerEntries.filter((ledgerEntry: LedgerEntry) => ledgerEntry.getStatus() === TransactionStatuses.Unreconciled && ledgerEntry.getOperationType() === OperationTypes.Credit).reduce((acc, entry) => {
      return acc + entry.getAmount();
    }, initAmount);
  }

  getReconciledDebitAmountSum(initAmount = 0) {
    return this.getReconciledDebitEntries().reduce((acc, entry) => acc - entry.getAmount(), initAmount);
  }

  getUnreconciledDebitAmountSum(initAmount = 0) {
    return this.getUnreconciledDebitEntries().reduce((acc, entry) => acc - entry.getAmount(), initAmount);
  }

  runPreReconciliationSteps() {
    this.step2RemoveReversals();
  }

  step2RemoveReversals() {
    this.ledgerEntries.forEach((entry) => {
      const isReversal = entry.getCategories().includes(REVERSAL);
      if (isReversal && entry.getStatus() === TransactionStatuses.Unreconciled) {
        const reversalTransaction = this.ledgerEntries.find((e) => {
          const haveCommonId = entry.getNumbers().includes(Number(e.getLedgerId()));
          if (!haveCommonId) return false;
          const haveSameAmount = entry.getAmount() === e.getAmount();
          if (!haveSameAmount) return false;
          const isUnreconciled = e.getStatus() === TransactionStatuses.Unreconciled;
          return haveCommonId && haveSameAmount && isUnreconciled;
        });
        if (reversalTransaction) {
          entry.reverse(reversalTransaction.getId());
          reversalTransaction.reverse(entry.getId());
        }
      }
    });
  }

  // getContentsArray(operationType: OperationTypes.Credit | OperationTypes.Debit): string[][] {
  //   const contentsArray = [];
  //   contentsArray.push(this.headers);
  //   this.ledgerEntries.forEach((ledgerEntry) => {
  //     // if (operationType === ledgerEntry.getOperationType()) contentsArray.push(ledgerEntry.toArray());
  //   });
  //   if (operationType === OperationTypes.Credit) this.bankCharges.forEach((bankCharge) => {
  //     // contentsArray.push(bankCharge.toArray());
  //   });
  //   console.log('contentsArray', contentsArray);
  //   return contentsArray;
  // }

}

export { Ledger };
