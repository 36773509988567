// import { BankStatementEntity } from './bounded-contexts/documents/domain/BankStatementEntity';
// import { DocumentEntity } from './bounded-contexts/documents/domain/DocumentEntity';
// import { LedgerEntity } from './bounded-contexts/documents/domain/LedgerEntity';

import { BankTransactionsStatement } from './domain/BankTransactionsStatement';
import { Ledger } from './domain/Ledger';
import { TDocumentDTO } from './infra/services/docs';

class SelectedIndex {
  private index: number;
  constructor() {
    this.index = -1;
  }
  setIndex(index: number) {
    this.index = index;
  }
  getIndex() {
    return this.index;
  }
}


const documents: TDocumentDTO[] = [];
const bankStatementsBook: BankTransactionsStatement[] = [];
const selectedLedgerBookIndex = new SelectedIndex();
const selectedBankStatementBookIndex = new SelectedIndex();
const ledgerBook: Ledger[] = [];
export { documents, bankStatementsBook, ledgerBook, selectedLedgerBookIndex, selectedBankStatementBookIndex };