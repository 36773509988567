import { areDatesWithinTwoBusinessNDays, areTwoDatesWithinNDays } from '../../../utils';
import { Reconciliation } from '../../Reconciliation';
import { OperationTypes, OrderByType, RuleNameType, RuleNames } from '../../common';
import { UniquenessChecker } from '../helpers/UniquenessChecker';

class Rule3 {
  private r: Reconciliation;
  private rule: RuleNames;

  constructor(reconciliation: Reconciliation, rule: RuleNames) {
    this.r = reconciliation;
    this.rule = rule;
  }

  public runRule(rule: RuleNameType, bankStatementUniqueChecker: UniquenessChecker, ledgerUniqueChecker: UniquenessChecker, orderBy?: OrderByType): void {
    let pass = 1;
    let reconciliations = 0;
    if (Reconciliation.db) this.r.populateDB(Reconciliation.db);
    else console.log('No DB');
    do {
      const message = `Running ${rule} pass ${pass}`;
      this.r.setMessage(message + ' - ' + this.r.getBankStatement().getReconciledTransactions().length + ' / ' + this.r.getBankStatement().getTransactions().length);
      this.r.delay(10);
      switch (rule) {
        case RuleNames.Rule3_1:
        case RuleNames.Rule3_2:
        case RuleNames.Rule3_3:
        case RuleNames.Rule3_4:
        case RuleNames.Rule3_5:
        case RuleNames.Rule3_6:
          reconciliations = this.rule3MainAlgorithm(bankStatementUniqueChecker, ledgerUniqueChecker, rule, pass, orderBy);
          break;
        default:
          throw new Error('Invalid rule name');
      } 
      // reconciliations = this.r.rule3MainAlgorithm(bankStatementUniqueChecker, ledgerUniqueChecker, rule, pass, orderBy);
     
      console.log(message, `- Reconciliations: ${reconciliations}`);
      if (reconciliations > 0) {
        pass++;
      }
    } while (reconciliations > 0);
  }

  /**
  * runReconciliationRule3_1
  * Tag & Amount are Unique	"- This means the Matching Pair identified is Unique. There are no other LTs or BTs with the same Tag and the same Amount 
  *
  *  - Verdict: we can Reconcile irrespective of the difference in date"
  *  Tag is Unique (but Amount appears in other transaction)	"- This means that there are no other LTs or BTs with the same Tag. However, there could be some LTs or BTs with the same Amount (but they do not have the same Tag associated with them as the Matching Pair)
  *
  *  - Verdict: we can Reconcile irrespective of the difference in date"
  *  Tag is Not Unique (it appears in other LTs), but the Amount is Unique (the other LTs with the same Tag have different amounts)	"- This means that there are other LTs or BTs with the same Tag, however, they don't have the same Amount. There is a risk here that the amount could be wrong, so, in order to prevent this, we will only reconcile if the difference in date between the BT and the LT (the Matching Pair), is 0, 1 or 2 working days. 
  *
  *  - Verdict: Reconcile if Date difference is 0-2 working days. Else, label as Unreconciled - perhaps in the 2nd loop it will be reconciled"
  *  Tag is Not Unique (it appears in other LTs), and the Amount is Not Unique (other LT(s) with the same Tag have the same amount)	"- This means there are multiple Matching Pairs. Given we don't know which one we should match, we can only match if the Date is exactly the same. There can be no difference. So, if we find a Matching Pair, and then identify that there are 2 additional LTs and 2 additional BTs with the same Tag and Amount, we will reconcile the BT being assessed with the LT with the same Tag, same Amount and the same Date.
  *  - If none of the LTs has the exact same Date, we don't reconcile
  *
  *  - Verdict: reconcile only if the Date is exactly the same. Else label as Unreconciled  - perhaps in the 2nd loop it will be reconciled"
  */
  private rule3MainAlgorithm(bankStatementUniqueChecker: UniquenessChecker, ledgerUniqueChecker: UniquenessChecker, ruleName: RuleNameType, pass: number, orderBy?: OrderByType): number {
    const DEBUG_TRANSACTION_ID = 0;
    const DEBUG_LEDGER_ENTRY_ID = 0;
    let reconciliations = 0;
    const unreconciledTransactions = this.r.bankStatement.getUnreconciledTransactions({orderBy});
    this.r.updateProgress();
    const message = `Running ${ruleName} pass ${pass}`;
    this.r.setMessage(message + ' - ' + this.r.getBankStatement().getReconciledTransactions().length + ' / ' + this.r.getBankStatement().getTransactions().length);
    for (const bankTransaction of unreconciledTransactions) {
      this.r.updateProgress();
      const entries = bankTransaction.getOperationType() === OperationTypes.Credit ? this.r.ledger?.getNonReconciledDebitEntries() : this.r.ledger?.getNonReconciledCreditEntries();
      if (entries) for (let i = 0; i < entries.length; i++) {
        const ledgerEntry = entries[i];
        const haveAtLeastOneCommonTag = this.r.haveAtLeastOneCommonTag(bankTransaction, ledgerEntry, ruleName).length > 0;
        const haveSameAmount = ledgerEntry.getAmount() === bankTransaction.getAmount();
        const areDatesWithin2BusinessDays = areDatesWithinTwoBusinessNDays(bankTransaction.getDate(), ledgerEntry.getDate(), 2);
        const areDateWithin30Days = areTwoDatesWithinNDays(bankTransaction.getDate(), ledgerEntry.getDate(), 30);
        const haveSameDate = ledgerEntry.getDate().getTime() === bankTransaction.getDate().getTime();
        if (DEBUG_TRANSACTION_ID === bankTransaction.getId() && DEBUG_LEDGER_ENTRY_ID === ledgerEntry.getId()) {
          console.log('******* same amount', ledgerEntry.getAmount(), bankTransaction.getAmount());
          console.log('haveSameAmount', haveSameAmount);
          console.log('areDatesWithin2BusinessDays', areDatesWithin2BusinessDays);
          console.log(ruleName, 'haveSameDate', haveSameDate, ledgerEntry.getDate().getTime(), bankTransaction.getDate().getTime());
        }
        // if (bankTransaction.getId() === 4 && ledgerEntry.getId() === 3) {
        //   console.log('pass', pass);
        //   // console.log('bankTranCategories', bankTransaction.getCategories());
        //   // console.log('ledgerEntryCategories', ledgerEntry.getCategories());
        //   console.log('bankTransaction.getDescription()', bankTransaction.getDescription());
        //   console.log('haveAtLeastOneCommonTag', haveAtLeastOneCommonTag, this.r.haveAtLeastOneCommonTag(bankTransaction, ledgerEntry, ruleName), ruleName);
        //   console.log('haveSameAmount', haveSameAmount);
        //   console.log('areDatesWithin2BusinessDays', areDatesWithin2BusinessDays);
        //   console.log('haveSameDate', haveSameDate);
        // }
        if (DEBUG_TRANSACTION_ID === bankTransaction.getId() && DEBUG_LEDGER_ENTRY_ID === ledgerEntry.getId()) {
          console.log(ruleName, 'haveAtLeastOneCommonTag', haveAtLeastOneCommonTag);
          // console.log('haveSameAmount', haveSameAmount);
        }
        if (haveAtLeastOneCommonTag && haveSameAmount) {
          const firstCommonTag = this.r.getCommonTags(bankTransaction, ledgerEntry, ruleName)[0];
          const isAmountUnique = 
            bankStatementUniqueChecker.isAmountUnique(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getOperationType()) 
            && ledgerUniqueChecker.isAmountUnique(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getOperationType());
            const isTagUnique = 
            bankStatementUniqueChecker.isTagUnique(bankTransaction.getId(), firstCommonTag, bankTransaction.getOperationType()) 
            && ledgerUniqueChecker.isTagUnique(ledgerEntry.getId(), firstCommonTag, ledgerEntry.getOperationType());
          // if (bankTransaction.getId() === 5 && ledgerEntry.getId() === 4) {
          //   console.log('bankTransaction.getDescription()', bankTransaction.getDescription());
          //   console.log('isAmountUnique', isAmountUnique, bankStatementUniqueChecker.getAmountList(bankTransaction.getAmount(), bankTransaction.getOperationType()), ledgerUniqueChecker.getAmountList(ledgerEntry.getAmount(), ledgerEntry.getOperationType()));
          //   console.log('isTagUnique', isTagUnique, bankStatementUniqueChecker.getTagList(firstCommonTag, bankTransaction.getOperationType()), ledgerUniqueChecker.getTagList(firstCommonTag, ledgerEntry.getOperationType()));
          //   console.log('areDatesWithin2BusinessDays', areDatesWithin2BusinessDays);
          //   console.log('haveSameDate', haveSameDate);
          //   console.log('tags', this.r.getCommonTags(bankTransaction, ledgerEntry, ruleName));
          //   // console.log('bankTransactionCategories', bankTransaction.getCategories());
          //   console.log('bankStatementUniqueChecker.isTagUnique(bankTransaction.getId(), firstCommonTag, bankTransaction.getOperationType())', bankStatementUniqueChecker.isTagUnique(bankTransaction.getId(), firstCommonTag, bankTransaction.getOperationType()));
          //   console.log('ledgerUniqueChecker.isTagUnique(ledgerEntry.getId(), firstCommonTag, ledgerEntry.getOperationType())', ledgerUniqueChecker.isTagUnique(ledgerEntry.getId(), firstCommonTag, ledgerEntry.getOperationType()), ledgerEntry.getId(), firstCommonTag, ledgerEntry.getOperationType());
          //   console.log('ledgerTags', ledgerEntry.getTags());
          // }
          if (DEBUG_TRANSACTION_ID === bankTransaction.getId() && DEBUG_LEDGER_ENTRY_ID === ledgerEntry.getId()) {
            console.log('isAmountUnique', isAmountUnique);
            console.log('isTagUnique', isTagUnique);
            
          }
          if (
                 (isAmountUnique && isTagUnique) 
              || ((isTagUnique && (ruleName === RuleNames.Rule3_1 || ruleName === RuleNames.Rule3_2)) || (isTagUnique && areDateWithin30Days))
              || (isAmountUnique && areDatesWithin2BusinessDays)
              || (haveSameDate)
              ) {
                reconciliations++;
                bankTransaction.reconcile([ledgerEntry.getId()], ruleName);
                bankStatementUniqueChecker.checkOut(bankTransaction.getId(), bankTransaction.getAmount(), this.r.getTags(bankTransaction, ruleName), bankTransaction.getDate(), bankTransaction.getOperationType());
                ledgerEntry.reconcile([bankTransaction.getId()], ruleName);
                ledgerUniqueChecker.checkOut(ledgerEntry.getId(), ledgerEntry.getAmount(), this.r.getTags(ledgerEntry, ruleName), ledgerEntry.getDate(), ledgerEntry.getOperationType());
                break;
              }
        }
        if (DEBUG_TRANSACTION_ID === bankTransaction.getId() && DEBUG_LEDGER_ENTRY_ID === ledgerEntry.getId()) {
          console.log();
        }
      };
    };
    return reconciliations;
  }

}

export { Rule3 };