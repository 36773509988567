import * as fuzzball from 'fuzzball';
import SqlJs from 'sql.js';
import { BankTransaction } from './BankTransaction';
import { BankTransactionsStatement } from './BankTransactionsStatement';
import { Ledger } from './Ledger';
import { LedgerEntry } from './LedgerEntry';
import { OperationTypes, OrderByTypes, RECONCILED, RuleNames, LEDGER_ENTRIES_TABLE, BANK_TRANSACTIONS_TABLE, SUGGESTION, RuleNameType, TransactionStatuses } from './common';
import { BankTransactionGroup } from './BankTransactionGroup';
import { populateDatabase } from './reconciliation/populateDatabase';
import { Rule4 } from './reconciliation/rules/Rule4';
import { Rule5 } from './reconciliation/rules/Rule5';
import { Rule3 } from './reconciliation/rules/Rule3';
import { UniquenessChecker } from './reconciliation/helpers/UniquenessChecker';
import { LedgerTransactionGroup } from './LedgerTransactionGroup';
import { Rule6 } from './reconciliation/rules/Rule6';

export class Reconciliation {
  public static db: SqlJs.Database;
  public bankStatement: BankTransactionsStatement;
  public ledger: Ledger;
  private progressCallback: (progress: number) => void;
  private messageCallback: (progress: string) => void;

  constructor(bankStatement: BankTransactionsStatement, ledger: Ledger, progressCallback?: (progress: number) => void, messageCallback?: (progress: string) => void) {
    this.bankStatement = bankStatement;
    this.ledger = ledger;
    const mockProgressCallback = (progress: number) => {};
    const mockMessageCallback = (message: string) => {};
    this.progressCallback = progressCallback || mockProgressCallback;
    this.messageCallback = messageCallback || mockMessageCallback;
  }
  
  static async initDB() {
    console.log('Initializing in-memory DB');
    const SQL = await SqlJs({ locateFile: file => 'https://cdnjs.cloudflare.com/ajax/libs/sql.js/1.8.0/sql-wasm.wasm'});
    Reconciliation.db = new SQL.Database();
    console.log('DB Loaded!');
  }

  public populateDB(db: SqlJs.Database) {
    populateDatabase(db, this.bankStatement, this.ledger);
  }

  getBankStatement() {
    return this.bankStatement;
  }

  getLedger() {
    return this.ledger;
  }

  setProgress(progress: number) {
    console.log('Progress:', Math.round(progress*100)/100+'%');
    this.progressCallback(progress);
  }

  setMessage(message: string) {
    console.log('Message:', message);
    this.messageCallback(message);
  }

  async updateProgress() {
    this.setProgress(this.bankStatement.getReconciledTransactions().length / this.bankStatement.getTransactions().length * 100);
    await this.delay(1);
  }

  private haveAtLeastOneCommonAlphanumericTag(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): boolean {
    const ledgerEntryTags = ledgerEntry.getAlphanumerics();
    const bankTransactionTags = bankTransaction.getAlphanumerics();
    // console.log('ledgerEntryAlphanumericTags', ledgerEntryAlphanumericTags);
    // console.log('bankTransactionAlphanumericTags', bankTransactionAlphanumericTags);
    return ledgerEntryTags.some((tag) => bankTransactionTags.includes(tag));
  }

  private getCommonAlphanumericTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): string[] {
    const ledgerEntryTags = ledgerEntry.getAlphanumerics();
    const bankTransactionTags = bankTransaction.getAlphanumerics();
    return ledgerEntryTags.filter((tag) => bankTransactionTags.includes(tag));
  }

  private getCommonCompoundTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): string[] {
    const ledgerEntryTags = ledgerEntry.getCompounds();
    const bankTransactionTags = bankTransaction.getCompounds();
    return ledgerEntryTags.filter((tag) => bankTransactionTags.includes(tag));
  }

  private getCommonCategoryTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): string[] {
    const ledgerEntryTags = ledgerEntry.getCategories();
    const bankTransactionTags = bankTransaction.getCategories();
    return ledgerEntryTags.filter((tag) => bankTransactionTags.includes(tag));
  }

  public getCommonTermTags(first: BankTransaction | BankTransactionGroup | LedgerEntry | LedgerTransactionGroup, second: BankTransaction | BankTransactionGroup | LedgerEntry  | LedgerTransactionGroup): string[] {
    const secondTags = second.getTerms();
    const firstTags = first.getTerms();
    return secondTags.filter((tag) => firstTags.includes(tag));
  }

  private getCommonFuzzyMatchTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): string[] {
    const bankTransactionTags = bankTransaction.getTags();
    const ledgerEntryTags = ledgerEntry.getTags();
    const commonTags: string[] = [];
    const matchThreshold = 80;
    for (const bankTag of bankTransactionTags) {
      for (const ledgerTag of ledgerEntryTags) {
        const matchScore = fuzzball.ratio(bankTag, ledgerTag);
        if (matchScore >= matchThreshold) {
          commonTags.push(bankTag);
          break;
        } 
      }
    }
    // if (commonTags.includes('client')) {
    //   console.log('bankTransactionTags', bankTransactionTags);
    //   console.log('ledgerEntryTags', ledgerEntryTags);
    //   console.log('commonTags', commonTags);
    // }
    return commonTags; 
  }

  getCommonTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry, ruleName: RuleNames): string[] {
    switch (ruleName) {
      case RuleNames.Rule3_1:
        const ledgerEntryAlphanumericTags = ledgerEntry.getAlphanumerics();
        const bankTransactionAlphanumericTags = bankTransaction.getAlphanumerics();
        return ledgerEntryAlphanumericTags.filter((tag) => bankTransactionAlphanumericTags.includes(tag));
      case RuleNames.Rule3_2:
        const ledgerEntryNumericTags = ledgerEntry.getNumbers();
        const bankTransactionNumericTags = bankTransaction.getNumbers();
        return ledgerEntryNumericTags.filter((tag) => bankTransactionNumericTags.includes(tag)).map((tag: number) => String(tag));
      case RuleNames.Rule3_3:
        const ledgerEntryCompoundTags = ledgerEntry.getCompounds();
        const bankTransactionCompoundTags = bankTransaction.getCompounds();
        return ledgerEntryCompoundTags.filter((tag) => bankTransactionCompoundTags.includes(tag));
      case RuleNames.Rule3_4:
        const ledgerEntryCategoryTags = ledgerEntry.getCategories();
        const bankTransactionCategoryTags = bankTransaction.getCategories();
        return ledgerEntryCategoryTags.filter((tag) => bankTransactionCategoryTags.includes(tag));
      case RuleNames.Rule3_5:
        const ledgerEntryTermTags = ledgerEntry.getTerms();
        const bankTransactionTermTags = bankTransaction.getTerms();
        return ledgerEntryTermTags.filter((tag) => bankTransactionTermTags.includes(tag));
      case RuleNames.Rule3_6:
      case RuleNames.Rule4_1:
      case RuleNames.Rule4_2:
        const ledgerEntryTagsTags = ledgerEntry.getTags();
        const bankTransactionTagsTags = bankTransaction.getTags();
        return ledgerEntryTagsTags.filter((tag) => bankTransactionTagsTags.includes(tag));
      default:
        throw new Error('Invalid rule name');
    }
  }

  getTags(object: BankTransaction | BankTransactionGroup | LedgerEntry, ruleName: RuleNames): string[] {
    switch (ruleName) {
      case RuleNames.Rule3_1:
        return object.getAlphanumerics();
      case RuleNames.Rule3_2:
        return object.getNumbers().map((num) => String(num));
      case RuleNames.Rule3_3:
        return object.getCompounds();
      case RuleNames.Rule3_4:
        return object.getCategories();
      case RuleNames.Rule3_5:
        return object.getTerms();
      case RuleNames.Rule3_6:
      case RuleNames.Rule4_1:
      case RuleNames.Rule4_2:
          return object.getTags();
      default:
        throw new Error('Invalid rule name');
    }
  }

  private getCommonNumericTags(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): string[] {
    const ledgerEntryAlphanumericTags = ledgerEntry.getNumbers();
    const bankTransactionAlphanumericTags = bankTransaction.getNumbers();
    return ledgerEntryAlphanumericTags.filter((tag) => bankTransactionAlphanumericTags.includes(tag)).map((tag: number) => String(tag));
  }
  
  private haveAtLeastOneCommonCategoryTag(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): boolean {
    const ledgerEntryCategories = ledgerEntry.getCategories();
    const bankTransactionCategories = bankTransaction.getCategories();
    return ledgerEntryCategories.some((category) => bankTransactionCategories.includes(category));
  }

  private haveAtLeastOneCommonWordTag(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry): boolean {
    const ledgerEntryTerms = ledgerEntry.getTerms();
    const bankTransactionTerms = bankTransaction.getTerms();
    return ledgerEntryTerms.some((word) => bankTransactionTerms.includes(word));
  }
  
  haveAtLeastOneCommonTag(bankTransaction: BankTransaction | BankTransactionGroup, ledgerEntry: LedgerEntry, ruleName: RuleNames): string[] {
    switch (ruleName) {
      case RuleNames.Rule3_1:
        return this.getCommonAlphanumericTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule3_2:
        return this.getCommonNumericTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule3_3:
        return this.getCommonCompoundTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule3_4:
        return this.getCommonCategoryTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule3_5:
        return this.getCommonTermTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule3_6:
        return this.getCommonFuzzyMatchTags(bankTransaction, ledgerEntry);
      case RuleNames.Rule4_1:
      case RuleNames.Rule4_2:
        return this.getCommonTermTags(bankTransaction, ledgerEntry);
      default:
        throw new Error('Invalid rule name');
    }
  }

  runPreReconciliationSteps() {
    this.ledger?.runPreReconciliationSteps();
  }

  runPostReconciliationSteps() {
    this.clearReconciledSuggestions();
  }

  clearReconciledSuggestions() {
    this.bankStatement?.getTransactions().forEach((transaction) => {
      if (transaction.getStatus() === TransactionStatuses.Suggestion) {
        transaction.getReconciliationIds().forEach((reconciliationId) => {
          if (this.ledger?.getTransactionById(reconciliationId).getStatus() === TransactionStatuses.Reconciled) {
            transaction.unreconcile();
          }
        });
      }
    });
  }

  async delay(ms: number) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  async run() {
    this.runPreReconciliationSteps();
    this.runRule3_1();
    this.setMessage('Running rule 3.2');
    await this.delay(10);
    this.runRule3_2();
    this.setMessage('Running rule 3.3');
    await this.delay(10);
    this.runRule3_3();
    this.setMessage('Running rule 3.4');
    await this.delay(10);
    this.runRule3_4();
    this.setMessage('Running rule 3.5');
    await this.delay(10);
    this.runRule3_5();
    this.setMessage('Running rule 3.6');
    await this.delay(10);
    this.runRule3_6();
    this.setMessage('Running rule 4.1');
    await this.delay(10);
    this.runRule4_1();
    this.setMessage('Running rule 4.2');
    await this.delay(10);
    this.runRule4_2();
    this.setMessage('Running rule 5.1');
    await this.delay(10);
    this.runRule5_1();
    this.setMessage('Running rule 5.2');
    await this.delay(10);
    this.runRule5_2();
    this.setMessage('Running rule 6.1');
    await this.delay(10);
    this.runRule6_1();
    this.runPostReconciliationSteps();
  }

  runRule3_1(): void {
    const rule = RuleNames.Rule3_1;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
  
    for (const bankTransaction of this.bankStatement.getUnreconciledTransactions({})) {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getAlphanumerics(), bankTransaction.getDate(), bankTransaction.getOperationType());
    }
    
    for (const ledgerEntry of this.ledger.getNonReconciledEntries()) {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getAlphanumerics(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    }
  
    const ruleAlgorithm = new Rule3(this, rule);
    ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  }
  // async runRule3_1(): Promise<void> {
  //   const rule = RuleNames.Rule3_1;
  //   const bankStatementUniqueChecker = new UniquenessChecker();
  //   const ledgerUniqueChecker = new UniquenessChecker();
  //   this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
  //     bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getAlphanumerics(), bankTransaction.getDate(), bankTransaction.getOperationType());
  //   });
  //   this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
  //     ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getAlphanumerics(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
  //   });
  //   const ruleAlgorithm = new Rule3(this, rule);
  //   await ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  // } 

  runRule3_2(): void {
    const rule = RuleNames.Rule3_2;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getNumbers().map((num) => String(num)), bankTransaction.getDate(), bankTransaction.getOperationType());
    });
    this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getNumbers().map((num) => String(num)), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    });
    const ruleAlgorithm = new Rule3(this, rule);
    ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  } 

  runRule3_3(): void {
    const rule = RuleNames.Rule3_3;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getCompounds(), bankTransaction.getDate(), bankTransaction.getOperationType());
    });
    this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getCompounds(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    });
    const ruleAlgorithm = new Rule3(this, rule);
    ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  } 

  runRule3_4(): void {
    const rule = RuleNames.Rule3_4;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getCategories(), bankTransaction.getDate(), bankTransaction.getOperationType());
    });
    this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getCategories(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    });
    const ruleAlgorithm = new Rule3(this, rule);
    ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  }

  runRule3_5(): void {
    const rule = RuleNames.Rule3_5;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getTerms(), bankTransaction.getDate(), bankTransaction.getOperationType());
    });
    this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getTerms(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    });
    const bankStatementFrequencyMap = new Map<string, number>();
    for (const transaction of this.bankStatement.getTransactions()) {
      transaction.getTerms().forEach((term) => { bankStatementFrequencyMap.set(term, (bankStatementFrequencyMap.get(term) || 0) + 1); });
    }
    // console.log('bankStatementFrequencyMap', bankStatementFrequencyMap);
    const ledgerFrequencyMap = new Map<string, number>();
    for (const entry of this.ledger.getEntries()) {
      entry.getTerms().forEach((term) => { ledgerFrequencyMap.set(term, (ledgerFrequencyMap.get(term) || 0) + 1); });
    }
    // console.log('ledgerFrequencyMap', ledgerFrequencyMap);
    // combine frequencyMaps
    const frequencyMap = new Map<string, number>();
    bankStatementFrequencyMap.forEach((frequency, term) => {
      frequencyMap.set(term, frequency);
    });
    ledgerFrequencyMap.forEach((frequency, term) => {
      frequencyMap.set(term, (frequencyMap.get(term) || 0) + frequency);
    });
    // console.log('frequencyMap', frequencyMap);
    const commonTerms = new Set<string>();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      const entries = bankTransaction.getOperationType() === OperationTypes.Credit ? this.ledger?.getNonReconciledDebitEntries() : this.ledger?.getNonReconciledCreditEntries();
        if (entries) for (let i = 0; i < entries.length; i++) {
          const ledgerEntry = entries[i];
          const haveAtLeastOneCommonTag = this.haveAtLeastOneCommonTag(bankTransaction, ledgerEntry, rule);
          if (haveAtLeastOneCommonTag.length > 0) {
            haveAtLeastOneCommonTag.forEach((term) => commonTerms.add(term));
          }
        }
      });
    // console.log('commonTerms', commonTerms);
    // clean frequencyMap
    frequencyMap.forEach((frequency, term) => {
      if (!commonTerms.has(term)) frequencyMap.delete(term);
    });
    const ruleAlgorithm = new Rule3(this, rule);
    ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker, {type: OrderByTypes.TermFrequency, frequencyMap});
  }

  runRule3_6(): void {
    const rule = RuleNames.Rule3_6;
    const bankStatementUniqueChecker = new UniquenessChecker();
    const ledgerUniqueChecker = new UniquenessChecker();
    this.bankStatement.getUnreconciledTransactions({}).forEach((bankTransaction: BankTransaction) => {
      bankStatementUniqueChecker.checkIn(bankTransaction.getId(), bankTransaction.getAmount(), bankTransaction.getTags(), bankTransaction.getDate(), bankTransaction.getOperationType());
    });
    this.ledger.getNonReconciledEntries().forEach((ledgerEntry: LedgerEntry) => {
      ledgerUniqueChecker.checkIn(ledgerEntry.getId(), ledgerEntry.getAmount(), ledgerEntry.getTags(), ledgerEntry.getDate(), ledgerEntry.getOperationType());
    });
    const ruleAlgorithm = new Rule3(this, rule);
     ruleAlgorithm.runRule(rule, bankStatementUniqueChecker, ledgerUniqueChecker);
  }

  runRule4_1(): void {
     this.runRule4(RuleNames.Rule4_1);
  }

  runRule4_2(): void {
     this.runRule4(RuleNames.Rule4_2);
  }

  private runRule4(rule: RuleNames): void {
    new Rule4(this, rule).run();
  }

  runRule5_1(): void {
    this.runRule5(RuleNames.Rule5_1);
  }

  runRule5_2(): void {
    this.runRule5(RuleNames.Rule5_2);
  }

  private runRule5(rule: RuleNames): void {
    new Rule5(this, rule).run();
  }

  runRule6_1(): void {
    this.runRule6(RuleNames.Rule6_1);
  }

  private runRule6(rule: RuleNames): void {
    new Rule6(this, rule).run();
  }

  reconcile(transaction: BankTransactionGroup | BankTransaction | LedgerTransactionGroup, counterpartIds: number[], rule: RuleNameType, state?: any): void {
    this.suggestOrReconcile(transaction, counterpartIds, rule, 'reconcile');
  }

  suggest(transaction: BankTransactionGroup | BankTransaction | LedgerTransactionGroup, counterpartIds: number[], rule: RuleNameType, state?: any): void {
    if (transaction.getStatus() !== SUGGESTION) this.suggestOrReconcile(transaction, counterpartIds, rule, 'suggest');
  }

  private suggestOrReconcile(transaction: BankTransactionGroup | BankTransaction | LedgerTransactionGroup, counterpartIds: number[], rule: RuleNameType, operation: 'reconcile' | 'suggest'): void {
    if (transaction.getClassName() === 'BankTransactionGroup' && counterpartIds.length === 1) {
      const bankTransactionGroup = transaction as BankTransactionGroup;
      const bankTransactionGroupIds = bankTransactionGroup.getIds();
      let SQL_UPDATE_STATEMENT = '';
      counterpartIds.forEach((entryId: number) => {
        SQL_UPDATE_STATEMENT += `UPDATE ${LEDGER_ENTRIES_TABLE} SET status = '${SUGGESTION}' WHERE id = ${entryId};\n`;
        const ledgerEntry = this.ledger.getEntries()[entryId-1];
        if (operation === 'reconcile') ledgerEntry.reconcile(bankTransactionGroupIds, rule);
        else if (operation === 'suggest') ledgerEntry.suggest(bankTransactionGroupIds, rule);
      });
      bankTransactionGroup.getTransactions().forEach((transaction: BankTransaction) => {
        SQL_UPDATE_STATEMENT += `UPDATE ${BANK_TRANSACTIONS_TABLE} SET status = '${SUGGESTION}' WHERE id = ${transaction.getId()};\n`;
        if (operation === 'reconcile') this.bankStatement.getTransactions()[transaction.getId()-1].reconcile(counterpartIds, rule);
        else if (operation === 'suggest') this.bankStatement.getTransactions()[transaction.getId()-1].suggest(counterpartIds, rule);
      });
      Reconciliation.db.run(SQL_UPDATE_STATEMENT);
    } else if (transaction.getClassName() === 'BankTransaction' && counterpartIds.length === 1) {
      const bankTransaction = transaction as BankTransaction;
      const entryId = counterpartIds[0];
      if (operation === 'reconcile') {
        bankTransaction.reconcile(counterpartIds, rule);
        this.getLedger().getTransactionById(entryId).reconcile([bankTransaction.getId()], rule);
      }
      else if (operation === 'suggest') {
        bankTransaction.suggest(counterpartIds, rule);
        this.getLedger().getTransactionById(entryId).suggest([bankTransaction.getId()], rule);
      }
      let SQL_UPDATE_STATEMENT = '';
      if (operation === 'reconcile') {
        SQL_UPDATE_STATEMENT += `UPDATE ${BANK_TRANSACTIONS_TABLE} SET status = '${RECONCILED}' WHERE id = ${bankTransaction.getId()};\n`;
        SQL_UPDATE_STATEMENT += `UPDATE ${LEDGER_ENTRIES_TABLE} SET status = '${RECONCILED}' WHERE id = ${entryId};\n`;
      }
      else if (operation === 'suggest') {
        SQL_UPDATE_STATEMENT += `UPDATE ${BANK_TRANSACTIONS_TABLE} SET status = '${SUGGESTION}' WHERE id = ${bankTransaction.getId()};\n`;
        SQL_UPDATE_STATEMENT += `UPDATE ${LEDGER_ENTRIES_TABLE} SET status = '${SUGGESTION}' WHERE id = ${entryId};\n`;
      }
      Reconciliation.db.run(SQL_UPDATE_STATEMENT);
    } else if (counterpartIds.length > 1) {
      // console.log('in else if counterpartsId > 1', transaction, counterpartIds, operation);
      const bankTransaction = transaction as BankTransaction;
      // const ledgerTransactionGroup = transaction as LedgerTransactionGroup;
      let SQL_UPDATE_STATEMENT = '';
      counterpartIds.forEach((entryId: number) => {
        SQL_UPDATE_STATEMENT += `UPDATE ${LEDGER_ENTRIES_TABLE} SET status = '${RECONCILED}' WHERE id = ${entryId};\n`;
        const ledgerEntry = this.getLedger().getEntries()[entryId-1];
        if (operation === 'reconcile') this.ledger.reconcile([ledgerEntry], [bankTransaction.getId()], rule);
        else if (operation === 'suggest') this.ledger.suggest([ledgerEntry], [bankTransaction.getId()], rule);
      });
      SQL_UPDATE_STATEMENT += `UPDATE ${BANK_TRANSACTIONS_TABLE} SET status = '${RECONCILED}' WHERE id = ${bankTransaction.getId()};\n`;       
      if (operation === 'reconcile') {
        bankTransaction.reconcile(counterpartIds, rule);
      }
      else if (operation === 'suggest') bankTransaction.suggest(counterpartIds, rule);
      Reconciliation.db.run(SQL_UPDATE_STATEMENT);
    } else {
      throw new Error('Unimplemented reconciliation case reached');
    }
  }
}
