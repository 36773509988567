import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, UN_RECONCILED } from '../common';

type GetUnreconciledLedgerEntriesWithTermTagsReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};
type Options = {date?: number, amount?: number};

const getUnreconciledLedgerEntriesWithTermTags = (db: SqlJs.Database, tag: string, options?: Options) => {
  let SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE status = '${UN_RECONCILED}' AND (LOWER(description) LIKE '%${tag}%' OR LOWER(reference) LIKE '%${tag}%')`;
  if (options?.date) SQL += ` AND date = ${options.date}`;
  if (options?.amount) SQL += ` AND amount = ${options.amount}`;
  const result = db.exec(SQL);
  const results: GetUnreconciledLedgerEntriesWithTermTagsReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getUnreconciledLedgerEntriesWithTermTags };
export type { GetUnreconciledLedgerEntriesWithTermTagsReturn };