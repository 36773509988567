import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, SUGGESTION, UN_RECONCILED } from '../common';
import { BankTransaction } from '../BankTransaction';

type GetNonReconciledLedgerEntriesWithSameAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getNonReconciledLedgerEntriesWithSameAmount = (db: SqlJs.Database, transaction: BankTransaction) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE amount = ? AND (status = '${UN_RECONCILED}' OR status = '${SUGGESTION}')`;
  const result = db.exec(SQL, [-transaction.getAccountingAmount()]);
  const results: GetNonReconciledLedgerEntriesWithSameAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getNonReconciledLedgerEntriesWithSameAmount };
export type { GetNonReconciledLedgerEntriesWithSameAmountReturn };