const isWeekend = (date: Date): boolean => {
    return date.getDay() === 6 || date.getDay() === 0;
}

type DateRangeType = {startDay: Date; finishDay: Date }
  
const getRangeOfWorkingDates = (currentDate: Date, daysRange: number): {startDay: Date; finishDay: Date } => {
    // days before
    const startDay = new Date(currentDate);
    let daysPassed = 0;
    while (daysPassed < daysRange) {
        startDay.setDate(startDay.getDate() - 1)
        if (!isWeekend(startDay)) {
            daysPassed += 1;
        }
    }
      
    // days after
    const finishDay = new Date(currentDate);
    let daysPassed2 = 0;
    while (daysPassed2 < daysRange) {
        finishDay.setDate(finishDay.getDate() + 1)
        if (!isWeekend(finishDay)) {
            daysPassed2 += 1;
        }
    }
      
    return {
        startDay,
        finishDay
    }  
}
  
const isDateWithinDateRange = (date: Date, range: DateRangeType) : boolean => {
    return date.valueOf() >= range.startDay.valueOf() && date.valueOf() <= range.finishDay.valueOf()
}

export const areTwoDatesWithinNDays = (date1: Date, date2: Date, n: number): boolean => {
  // find if two dates are within n calendar days
  const diff = Math.abs(date1.getTime() - date2.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays <= n;
}

export const areDatesWithinTwoBusinessNDays = (date1: Date, date2: Date, n: number): boolean => {
    const range = getRangeOfWorkingDates(date1, n);
    return isDateWithinDateRange(date2, range)
}