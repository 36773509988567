function transliterate(input: string): string {
  // Initialize the conversion maps
  const cyrillicToLatinMap: { [key: string]: string } = {
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo', 'ж': 'zh', 'з': 'z', 'и': 'i',
      'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
      'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ъ': '', 'ы': 'y', 'ь': '',
      'э': 'e', 'ю': 'yu', 'я': 'ya'
  };
  const greekToLatinMap: { [key: string]: string } = {
      'α': 'a', 'β': 'b', 'γ': 'g', 'δ': 'd', 'ε': 'e', 'ζ': 'z', 'η': 'i', 'θ': 'th', 'ι': 'i', 'κ': 'k',
      'λ': 'l', 'μ': 'm', 'ν': 'n', 'ξ': 'x', 'ο': 'o', 'π': 'p', 'ρ': 'r', 'σ': 's', 'ς': 's', 'τ': 't',
      'υ': 'y', 'φ': 'f', 'χ': 'ch', 'ψ': 'ps', 'ω': 'o', 'ά': 'a', 'έ': 'e', 'ί': 'i', 'ό': 'o', 'ύ': 'y',
      'ή': 'i', 'ώ': 'o', 'ϊ': 'i', 'ϋ': 'y', 'ΰ': 'y', 'ΐ': 'i'
  };

  // Convert to lower case
  let lowerCaseInput = input.toLowerCase();

  // Normalize unicode string (decompose accented letters)
  let normalizedInput = lowerCaseInput.normalize('NFD');

  // Replace Cyrillic and Greek characters
  let result = '';
  for (let i = 0; i < normalizedInput.length; i++) {
    let char = normalizedInput[i];

    // Check if character is Cyrillic and replace
    if (cyrillicToLatinMap.hasOwnProperty(char)) {
        result += cyrillicToLatinMap[char];
    }
    // Check if character is Greek and replace
    else if (greekToLatinMap.hasOwnProperty(char)) {
        result += greekToLatinMap[char];
    }
    // If character is non-latin letter character, replace with empty string
    else if (/[^a-z0-9\s\-<>«»?£€$§!@#%^&*()_+={}[\]|\\/:;"'~`,.“”]/.test(char)) {
        result += '';
    }
    // If character is neither Cyrillic nor Greek, keep as is
    else {
      result += char;
    }
  }

  return result;
}
    
export { transliterate };
