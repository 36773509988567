import SqlJs from 'sql.js';
import { BANK_TRANSACTIONS_TABLE, SUGGESTION, UN_RECONCILED } from '../common';
import { BankTransaction } from '../BankTransaction';

type GetOtherNonReconciledBankTransactionsWithSameAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number, bankId: number};

const getOtherNonReconciledBankTransactionsWithSameAmount = (db: SqlJs.Database, transaction: BankTransaction) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount, bankId FROM ${BANK_TRANSACTIONS_TABLE} WHERE id <> ? AND (status = '${UN_RECONCILED}' OR status = '${SUGGESTION}')`;
  const result = db.exec(SQL, [transaction.getId()]);
  const results: GetOtherNonReconciledBankTransactionsWithSameAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4], bankId: row[5]});
  });
  return results;
}

export { getOtherNonReconciledBankTransactionsWithSameAmount };
export type { GetOtherNonReconciledBankTransactionsWithSameAmountReturn };