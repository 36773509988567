import { OperationTypes } from "../../common";

class UniquenessChecker {
  private map = new Map<string, Set<number | string>>();
  checkIn(id: number | string, amount: number, tags: string[], date: Date, operationType: OperationTypes): void {
    // console.log('checkin', id, tags, operationType);
    const amountKey = this.getAmountKey(amount, operationType);
    if (this.map.has(amountKey)) {
      // if (tags[0] === '5123467') console.log('5123467 already in map'); 
      const ids = this.map.get(amountKey);
      if (ids) ids.add(id);
    } else {
      // if (tags[0] === '5123467' && id === 7) console.log('adding 5123467 to map'); 
      this.map.set(amountKey, new Set<number | string>([id]));
    }
    const dateKey = this.getDateKey(date, operationType);
    if (this.map.has(dateKey)) {
      const ids = this.map.get(dateKey);
      if (ids) ids.add(id);
    } else {
      this.map.set(dateKey, new Set<number | string>([id]));
    }
    tags.forEach((tag: string) => {
      const tagKey = this.getTagKey(tag, operationType);
      if (this.map.has(tagKey)) {
        const ids = this.map.get(tagKey);
        if (ids) ids.add(id);
      } else {
        this.map.set(tagKey, new Set<number | string>([id]));
      }
      if (this.map.has(dateKey+tagKey)) {
        const ids = this.map.get(dateKey+tagKey);
        if (ids) ids.add(id);
      } else {
        this.map.set(dateKey+tagKey, new Set<number | string>([id]));
      }
    });
  }

  checkOut(id: number | string, amount: number, tags: string[], date: Date, operationType: OperationTypes): void {

    const amountKey = this.getAmountKey(amount, operationType);
    if (this.map.has(amountKey)) {
      const ids = this.map.get(amountKey);
      if (ids) ids.delete(id);
    }
    const dateKey = this.getDateKey(date, operationType);
    if (this.map.has(dateKey)) {
      const ids = this.map.get(dateKey);
      if (ids) ids.delete(id);
    }
    tags.forEach((tag: string) => {
    const tagKey = this.getTagKey(tag, operationType);
      if (this.map.has(tagKey)) {
        const ids = this.map.get(tagKey);
        if (ids) ids.delete(id);
      }
    });
  }

  isAmountUnique(id: number | string, amount: number, operationType: OperationTypes): boolean {
    const amountKey = this.getAmountKey(amount, operationType);
    const amountValue = this.map.get(amountKey);
    // if (id === '3+4') console.log('3+4 ******** map', amountValue);
    if (amountValue) {
      return amountValue.size === 1;
    }
    return false;
  }

  isDateUnique(id: number | string, date: Date, operationType: OperationTypes): boolean {
    const dateKey = this.getDateKey(date, operationType);
    const dateValue = this.map.get(dateKey);
    if (dateValue) {
      return dateValue.size === 1;
    }
    return false;
  }

  isTagUnique(id: number | string, tag: string, operationType: OperationTypes): boolean {
    const tagKey = this.getTagKey(tag, operationType);
    const tagValue = this.map.get(tagKey);
    // if (tag === '5123467' && id === 7) {
    //   console.log('***** isTagUnique', tagKey, tagValue);
    // }
    if (id === '15+16') console.log('15+16 ******** map', tagValue);
    if (tagValue) {
      return tagValue.size === 1;
    }
    return false;
  }

  isTagAndDateUnique(id: number | string, tag: string, date: Date, operationType: OperationTypes): boolean {
    const tagKey = this.getTagKey(tag, operationType);
    const dateKey = this.getDateKey(date, operationType);
    const tagAndDateValue = this.map.get(dateKey+tagKey);
    if (tagAndDateValue) {
      return tagAndDateValue.size === 1;
    }
    return false;
  }

  getAmountList(amount: number, operationType: OperationTypes): (string | number)[] {
    const amountKey = this.getAmountKey(amount, operationType);
    const amountValue = this.map.get(amountKey);
    if (amountValue) {
      return Array.from(amountValue);
    }
    return [];
  }

  getTagList(tag: string, operationType: OperationTypes): (string | number)[] {
    const tagKey = this.getTagKey(tag, operationType);
    const tagValue = this.map.get(tagKey);
    if (tagValue) {
      return Array.from(tagValue);
    }
    return [];
  }

  private getTagKey(tag: string, operationType: OperationTypes): string {
    return `Tag: ${tag}-${operationType}`;
  };

  private getAmountKey(amount: number, operationType: OperationTypes): string {
    return `Amount: ${amount}-${operationType}`;
  }

  private getDateKey(date: Date, operationType: OperationTypes): string {
    return `Date: ${date.getTime()}-${operationType}`;
  }
}

export { UniquenessChecker };
