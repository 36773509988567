import { TBankStatementDTO } from '../infra/services/docs';
import { BankTransaction } from './BankTransaction';
import {
  NonReconciledStatuses, 
  OperationTypes,
  TransactionStatuses,
  OperationType,
  OrderByTypes,
  OrderByType
} from './common';

class BankTransactionsStatement {
  private id: string;
  private name: string;
  private transactions: BankTransaction[];
  private headers: string[];

  constructor(bankStatement: TBankStatementDTO) {
    if (bankStatement.type !== 'bank statement') throw new Error('Invalid type provided!');
    if (!bankStatement.contents) throw new Error('No bank statement contents found!');
    if (bankStatement.contents.length < 2) throw new Error('Invalid number of bank statement rows!');
    this.id = bankStatement.id;
    this.name = bankStatement.name;
    this.transactions = [];
    this.headers = bankStatement.contents[0];
    for (let i = 1; i < bankStatement.contents.length; i++) {
      const row = bankStatement.contents[i];
      if (row.filter((cell) => cell !== '').length > 0)
        this.transactions.push(BankTransaction.createFromArray(this.headers, row, i));
    }
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getOpeningBalance() {
    const firstTransaction = this.transactions[0];
    if (firstTransaction.getOperationType() === OperationTypes.Credit) return firstTransaction.getBalance() + firstTransaction.getAmount();
    return firstTransaction.getBalance() - firstTransaction.getAmount();
  }

  getClosingBalance() {
    const lastTransaction = this.transactions[this.transactions.length - 1];
    return lastTransaction.getBalance();
  }

  getReconciledTransactions() {
    return this.transactions.filter((transaction) => [TransactionStatuses.Suggestion, TransactionStatuses.Reconciled].includes(transaction.getStatus()));
  }

  getReconciledCreditTransactions() {
    return this.transactions.filter((transaction) => [TransactionStatuses.Suggestion, TransactionStatuses.Reconciled].includes(transaction.getStatus()) && transaction.getOperationType() === OperationTypes.Credit);
  }

  getReconciledDebitTransactions() {
    return this.transactions.filter((transaction) => [TransactionStatuses.Suggestion, TransactionStatuses.Reconciled].includes(transaction.getStatus()) && transaction.getOperationType() === OperationTypes.Debit);
  }

  getUnreconciledTransactions(props: {includeSuggestions?: boolean, orderBy?: OrderByType}) {
    const { includeSuggestions, orderBy } = props;
    if (orderBy) {
      if (orderBy.type === OrderByTypes.TermFrequency) {
        if (!orderBy.frequencyMap) throw new Error('No frequency map provided!');
        // return array of transactions sorted by frequency of terms in orderBy.frequencyMap in ascending order
        const sortedArray = this.transactions.filter((transaction) => includeSuggestions ? [TransactionStatuses.Unreconciled].includes(transaction.getStatus()) : transaction.getStatus() === TransactionStatuses.Unreconciled).sort((a, b) => {
          const aTerms = a.getTerms();
          const bTerms = b.getTerms();
          aTerms.filter((term) => !orderBy.frequencyMap?.has(term));
          bTerms.filter((term) => !orderBy.frequencyMap?.has(term));
          const aFrequency = orderBy.frequencyMap?.get(aTerms[0]) || 0;
          const bFrequency = orderBy.frequencyMap?.get(bTerms[0]) || 0;
          return aFrequency - bFrequency;
        });
        // console.log('originalArray', this.transactions.filter((transaction) => transaction.getStatus() === TransactionStatuses.Unreconciled));
        // console.log('sortedArray', sortedArray);
        // console.log('frequencyMap', orderBy.frequencyMap);
        return sortedArray;
      }
    }
    return this.transactions.filter((transaction) => transaction.getStatus() === TransactionStatuses.Unreconciled);
  }

  getUnreconciledCreditTransactions() {
    return this.transactions.filter((transaction) => (transaction.getStatus() === TransactionStatuses.Unreconciled) && transaction.getOperationType() === OperationTypes.Credit);
  }

  getUnreconciledDebitTransactions() {
    return this.transactions.filter((transaction) => (transaction.getStatus() === TransactionStatuses.Unreconciled) && transaction.getOperationType() === OperationTypes.Debit);
  }

  getNonReconciledTransactions() {
    return this.transactions.filter((transaction) => NonReconciledStatuses.includes(transaction.getStatus()));
  }

  getNonReconciledCreditTransactions() {
    return this.transactions.filter((transaction) => NonReconciledStatuses.includes(transaction.getStatus()) && transaction.getOperationType() === OperationTypes.Credit);
  }

  getNonReconciledDebitTransactions() {
    return this.transactions.filter((transaction) => NonReconciledStatuses.includes(transaction.getStatus()) && transaction.getOperationType() === OperationTypes.Debit);
  }

  getReconciledTransactionsAmountSum(initAmount = 0) {
    return this.getReconciledTransactions().reduce((acc, transaction) => {
      if (transaction.getOperationType() === OperationTypes.Credit) return acc + transaction.getAmount();
      return acc - transaction.getAmount();
    }, initAmount);
  }

  getReconciledCreditTransactionsAmountSum(initAmount = 0) {
    return this.getReconciledCreditTransactions().reduce((acc, transaction) => acc + transaction.getAmount(), initAmount);
  }

  getReconciledDebitTransactionsAmountSum(initAmount = 0) {
    return this.getReconciledDebitTransactions().reduce((acc, transaction) => acc - transaction.getAmount(), initAmount);
  }

  getUnreconciledTransactionsAmountSum(initAmount = 0) {
    return this.getUnreconciledTransactions({}).reduce((acc, transaction) => {
      if (transaction.getOperationType() === OperationTypes.Credit) return acc - transaction.getAmount();
      return acc + transaction.getAmount();
    }, initAmount);
  }

  getUnreconciledCreditTransactionsAmountSum(initAmount = 0) {
    return this.getUnreconciledCreditTransactions().reduce((acc, transaction) => acc + transaction.getAmount(), initAmount);
  }

  getUnreconciledDebitTransactionsAmountSum(initAmount = 0) {
    return this.getUnreconciledDebitTransactions().reduce((acc, transaction) => acc - transaction.getAmount(), initAmount);
  }

  getTransactions() {
    return this.transactions;
  }

  getTransactionById(id: number) {
    return this.transactions[id - 1];
  }

  getContentsArray(operationType: OperationType): string[][] {
    const contentsArray = [];
    contentsArray.push(this.headers);
    this.transactions.forEach((transaction) => {
      // if (operationType === transaction.getOperationType()) contentsArray.push(transaction.toArray());
    });
    console.log('contentsArray', contentsArray);
    return contentsArray;
  }

}

export { BankTransactionsStatement };