import React, { useEffect, useState } from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { read, WorkBook, utils, WorkSheet, writeFileXLSX } from 'xlsx';
import { useDropzone } from 'react-dropzone';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { VALID_FILE_TYPES } from '../../Constants';
// import { DocumentEntity } from '../../bounded-contexts/documents/domain/DocumentEntity';
import { DocumentsService } from '../../DI';
import { TBankStatementDTO, TDocumentDTO, TLedgerDTO } from '../../infra/services/docs';
import { bankStatementsBook, ledgerBook, selectedBankStatementBookIndex, selectedLedgerBookIndex } from '../../ObjectFactory';
// import BasicTable from '../tables/BasicTable';
import Button from '@mui/material/Button';
import { BankTransactionsStatement } from '../../domain/BankTransactionsStatement';
import { Ledger } from '../../domain/Ledger';
import { ReconciliationReport } from '../reconciliation-report';
import { BankTransaction } from '../../domain/BankTransaction';
import { LedgerEntry } from '../../domain/LedgerEntry';
import { Reconciliation } from '../../domain/Reconciliation';
// import { Reconciliation } from '../../domain/Reconciliation';
// import { NameVO } from '../../bounded-contexts/documents/domain/NameVO';
// import { DocumentTypeVO } from '../../bounded-contexts/documents/domain/DocumentTypeVO';
// import { addDocument } from '../../slices/documents/simpleDocumentsSlice';
// import { useAppDispatch } from '../../app/hooks';
// interface IFileDropzoneProps {
//   setData: (data: any) => void;
// }

const columnLettersToNumber = (letters: string) => {
  letters = letters.replace(/[0-9]/g, '');
  let number = 0;
  for (let i = 0; i < letters.length; i++) {
    number *= 26;
    number += letters.charCodeAt(i) - 64;
  }
  return number-1;
};

const sheetsToArray = (sheetData: {
  [sheet: string]: WorkSheet;
}): {[sheetName: string]: string[][]} => {
  const sheets: {[sheetName: string]: string[][]} = {};
 Object.keys(sheetData).map((sheetName: string) => {
    const sheetArray: string[][] = [];
    console.log('sheetName', sheetName);
    const sheet = sheetData[sheetName];
    console.log('sheet', sheet['!ref']?.split(':'));
    const [, end] = sheet['!ref']?.split(':') || ['A1', 'A1'];
    const maxColumn = columnLettersToNumber(end);
    console.log('end', end, maxColumn);
    let rowIndex = 0;
    utils.sheet_to_json(sheet, { header: 'A', raw: true, blankrows: true }).map((row: any) => {
      let initRow: string[] = [];
      for (let i = 0; i <= maxColumn; i++) {
        initRow = [...initRow, ''];
      };
      sheetArray[rowIndex] = initRow;
      // console.log('row', row);
      Object.keys(row).map((key: string) => {
        const columnIndex = columnLettersToNumber(key);
        sheetArray[rowIndex][columnIndex] = row[key];
        return true;
      });
      rowIndex++;
      return true;
    });
    sheets[sheetName] = sheetArray;
    return true;
  });
  return sheets;
};

const readWorkbook = async (file: any): Promise<WorkBook> => {
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;  // !! converts object to boolean 
  return await new Promise((resolve, reject) => {
    reader.onabort = () => reject('file reading was aborted');
    reader.onerror = () => reject('file reading has failed');
    reader.onload = function(e) {
      if (e) {
        var contents = e.target?.result;
        resolve(read(contents, { type: rABS ? 'binary' : 'array' }));
      }
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

const getWorkbookArray = async (file: File): Promise<{[sheetName: string]: string[][]}> => {
  const workbook = await readWorkbook(file);
  const sheets = workbook.Sheets;
  return sheetsToArray(sheets);
};

function FileDropzone (props: {documents: TDocumentDTO[]}) {
  const { documents } = props;
  const [bankStatementsTransactions, setBankStatementsTransactions] = useState<BankTransaction[]>([]);
  const [ledgerEntries, setLedgerEntries] = useState<LedgerEntry[]>([]);
  const [bankStatement, setBankStatement] = useState<BankTransactionsStatement | undefined>();
  const [ledger, setLedger] = useState<Ledger | undefined>();
  const [reconciled, setReconciled] = useState<boolean>(false);
  const [filesLoaded, setFilesLoaded] = useState<boolean | number>(false);
  const [runningReconciliation, setRunningReconciliation] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>('');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: VALID_FILE_TYPES
  });

  const handleBankStatementChange = (event: SelectChangeEvent) => {
    const bankStatementId = event.target.value as string;
    let index = 0;
    let i = 0;
    // const bankStatement = 
    bankStatementsBook.find((bs) => {
      const isEqual = bs.getId() === bankStatementId;
      if (isEqual) {
        index = i;
      }
      i++;
      return isEqual;
    });
    selectedBankStatementBookIndex.setIndex(index);
    setFilesLoaded(Math.random());
    // setBankStatement(bankStatement);
  };

  const handleLedgerChange = (event: SelectChangeEvent) => {
    const ledgerId = event.target.value as string;
    let index = 0;
    let i = 0;
    // const ledger = 
    ledgerBook.find((l) => {
      const isEqual = l.getId() === ledgerId;
      if (isEqual) {
        index = i;
      }
      i++;
      return isEqual;
    });
    selectedLedgerBookIndex.setIndex(index);
    setFilesLoaded(Math.random());
    // setLedger(ledger);
  };

  const handleReconcileButtonClick = async () => {
    if (selectedBankStatementBookIndex.getIndex() > -1 && selectedLedgerBookIndex.getIndex() > -1) {
      setRunningReconciliation(true);
      await Reconciliation.initDB();
      const bankStatement = bankStatementsBook[selectedBankStatementBookIndex.getIndex()];
      let ledger: Ledger = ledgerBook[selectedLedgerBookIndex.getIndex()];
      const reconciliation = new Reconciliation(bankStatement, ledger, setProgress, setMessage);
      new Promise(async (resolve) => {
        reconciliation.runPreReconciliationSteps();
        await reconciliation.run();
        resolve(true);
      }).then(() => {
        setBankStatement(reconciliation.getBankStatement());
        setLedger(reconciliation.getLedger());
        setReconciled(true);
        console.log('Bank Statement transactions:', reconciliation.getBankStatement().getTransactions());
        console.log('Ledger transactions:', reconciliation.getLedger().getEntries());
        if (reconciliation.getLedger()) setLedgerEntries(reconciliation.getLedger().getEntries());
        setBankStatementsTransactions(reconciliation.getBankStatement().getTransactions());
        setRunningReconciliation(false);
      });
    }
  };

  const handleDownloadXLSXButtonClick = () => {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById('reconciliation-report');

    // Extract Data (create a workbook object from the table)
    var workbook = utils.table_to_book(table_elt);

    // Process Data (add a new row)
    var ws = workbook.Sheets['Reconciliation Report'];
    utils.sheet_add_aoa(ws, [['Created '+new Date().toISOString()]], {origin:-1});

    // Package and Release Data (`writeFile` tries to write and save an XLSB file)
    writeFileXLSX(workbook, 'Report.xlsx');
  };

  useEffect(() => {
    async function processFiles() {
      acceptedFiles.map(async (file: File) => {
        const temp = getWorkbookArray(file);
        const filename = file.name;
        const workbook = await temp;
        const documentDTO = await DocumentsService.addDocument(filename, workbook);
        documents.push(documentDTO);
        console.log('documents', documents);
        Object.keys(documentDTO.contents).map((key: string) => {
          const [type, doc] = DocumentsService.detectDocumentCategory(documentDTO, key);
          switch (type) {
            case 'bank statement':
              console.log('bank statement', doc);
              if (selectedBankStatementBookIndex.getIndex() === -1) selectedBankStatementBookIndex.setIndex(0);
              bankStatementsBook.push(new BankTransactionsStatement(doc as TBankStatementDTO));
              break;
            case 'ledger':
              console.log('ledger', doc);
              if (selectedLedgerBookIndex.getIndex() === -1) selectedLedgerBookIndex.setIndex(0);
              ledgerBook.push(new Ledger(doc as TLedgerDTO));
              break;
            default: 
              console.log('default', doc);
          }
          return true;
        });
        setFilesLoaded(Math.random());
        return true;
      });
    }
    processFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);
  
  // const files = acceptedFiles.map((file: FileWithPath) => (
  //   <li key={file.path}>
  //     {file.path} - {file.size} bytes
  //   </li>
  // ));
  

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p style={{margin: '0'}}><UploadFileIcon style={{fontSize: '80px', margin: '0'}} fontSize="large" /></p>
        <p style={{fontSize: '25px'}}>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {runningReconciliation && <div>Progress: {progress.toFixed(2)}%</div>}
      {runningReconciliation && <div style={{height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
<GridLoader color="#7404e4" /></div>}
      {runningReconciliation && <div style={{fontSize: '12px'}}>{message}</div>}
      <div>{ !runningReconciliation && selectedLedgerBookIndex.getIndex() > -1 && selectedBankStatementBookIndex.getIndex() > -1 && <Button onClick={handleReconcileButtonClick} style={{margin: '10px'}}><JoinInnerIcon fontSize="large" /> Reconcile</Button> }
      { reconciled && selectedLedgerBookIndex.getIndex() > -1 && selectedBankStatementBookIndex.getIndex() > -1 && <Button onClick={handleDownloadXLSXButtonClick} style={{margin: '10px'}}><SimCardDownloadIcon fontSize="large" />Download</Button> }</div>
      { !runningReconciliation && filesLoaded && selectedLedgerBookIndex.getIndex() > -1 && <div><FormControl style={{width: '320px', margin: '10px'}}>
        <InputLabel id="bank-statement-label">Ledger</InputLabel>
        <Select
          labelId="bank-statement-label"
          id="bank-statement-select"
          value={ledgerBook[selectedLedgerBookIndex.getIndex()].getId()}
          label={ledgerBook[selectedLedgerBookIndex.getIndex()].getName()}
          onChange={handleLedgerChange}
        >
          { ledgerBook.map((ledger: Ledger) => (<MenuItem key={ledger.getName()} value={ledger.getId()}>{ledger.getName()}</MenuItem>)) }
        </Select>
      </FormControl></div> }
      { !runningReconciliation && filesLoaded && selectedBankStatementBookIndex.getIndex() > -1 && <div><FormControl style={{width: '320px', margin: '10px'}}>
        <InputLabel id="bank-statement-label">Bank Statement</InputLabel>
        <Select
          labelId="bank-statement-label"
          id="bank-statement-select"
          value={bankStatementsBook[selectedBankStatementBookIndex.getIndex()].getId()}
          label={bankStatementsBook[selectedBankStatementBookIndex.getIndex()].getName()}
          onChange={handleBankStatementChange}
        >
          { bankStatementsBook.map((statement: BankTransactionsStatement) => (<MenuItem key={statement.getName()} value={statement.getId()}>{statement.getName()}</MenuItem>)) }
        </Select>
      </FormControl></div> }
      {(bankStatementsTransactions.length > 0 && ledgerEntries.length > 0 && bankStatement && ledger) && <ReconciliationReport bankStatement={bankStatement} ledger={ledger} /> }
      {/* { bankStatement && <FormControl style={{width: '90%', margin: '10px'}}>
        <InputLabel id="credit-or-debit-label">Operation</InputLabel>
        <Select
          labelId="credit-or-debit-label"
          id="credit-or-debit-select"
          value={operationType}
          label={operationType === 'debit' ? 'Debit' : 'Credit'}
          onChange={(event) => setOperationType(event.target.value as 'debit' | 'credit')}
        >
          <MenuItem key="debit" value="debit">Debit</MenuItem>
          <MenuItem key="credit" value="credit">Credit</MenuItem>
        </Select>
      </FormControl> }
      { bankStatement && <BasicTable data={bankStatement.getContentsArray(operationType)}/> } */}
      {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
    </section>
  );
};

export { FileDropzone };