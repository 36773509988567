import { onlyUnique } from '../utils';

const moo = require('moo');

type LexerOptions = {
  [tokenType: string]: RegExp | string | string[];
}

const defaultOptions: LexerOptions = {
  date:     /(?:(?:0[1-9]|1[0-9]|2[0-9]|3[0-1]|[1-9])\/(?:[1-9]|1[0-2]|0[1-9])\/(?:20[0-9][0-9])|(?:0[1-9]|3[0-1]|1[0-9]|2[0-9]|[1-9])-(?:[1-9]|1[0-2]|0[1-9])-(?:20[0-9][0-9])|(?:20[0-9][0-9])-(?:[1-9]|1[0-2]|0[1-9])-(?:0[1-9]|3[0-1]|1[0-9]|2[0-9]|[1-9]))/,
  stopList: ['sepa', 'processing fee',	'incoming',	'reference',	'payment',	'outgoing',	'transfer'],
  bankCharges: ['bc', 'b/c', 'bank charge', 'charges', 'chrg', 'fees', 'commission', 'interest', 'chrgs'],
  reversal: ['rev tr', 'reverse tr', 'rev transaction', 'reverse transaction', 'reversal', 'reverse', 'rev', 'revs', 'cor', 'corr', 'correction', 'cancel'],
  cheque:   ['cheque', 'chq', 'cheq', 'chq no', 'check'],
  invoice: ['invoice', 'inv', 'invo', 'inv.', 'invoic', 'invoicen', 'invoiceno', 'invoicenumber', 'invoicenumber'],
  withdrawal: ['withdrawal', 'withdraw', 'wdraw'],
  number:   /[0-9][0-9][0-9][0-9][0-9]+/,
  term: /[a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z][a-zA-Z]+/,
  alphanumeric: /[a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9]+/,
  // what to do with '-'?  
  ignore:   ['-', '<', '>', '«', '»', '?', '£', '€', '$', '§', '!', '@', '#', '%', '^', '&', '*', '(', ')', '_', '+', '=', '{', '}', '[', ']', '|', '\\', '/', ':', ';', '"', "'", '~', '`', ',', '.', '“', '”'],
  shortNumber: /[0-9]+/,
  WS:       /[ \t]+/,
  string:   /[a-zA-Z]+/,
};

type Token = {
  type: string,
  value: string,
  text: string,
  toString: [Function: () => string],
  offset: number,
  lineBreaks: number,
  line: number,
  col: number
}

const defaultCategories = ['cheque', 'withdrawal', 'reversal', 'bankCharges', 'invoice'];

export class Lexer {
  private lexer: any;
  private tokens: Token[] = [];
  private alphanumeric: null | string[] = [];
  private categories: null | string[] = [];
  private compounds: null | string[] = [];
  private terms: null | string[] = [];
  private numbers: null | number[] = [];

  constructor(text: string, options = defaultOptions) {
     this.lexer = moo.compile(options);
     this.lexer.reset(text.toLowerCase().trim());
      let token = this.lexer.next();
      while (token) {
        this.tokens.push(token);
        token = this.lexer.next();
      }
  }

  getAlphanumeric() {
    if (this.alphanumeric !== null && this.alphanumeric.length > 0) return this.alphanumeric;
    else this.alphanumeric = [];
    this.tokens.forEach(token => {
      if (token.type === 'alphanumeric') {
        this.alphanumeric!.push(token.value);
      }
    });
    return this.alphanumeric.filter(onlyUnique);
  }
  
  getCategories(categoriesOptions = defaultCategories) {
    if (this.categories !== null && this.categories.length > 0) return this.categories;
    else this.categories = [];
    this.tokens.forEach(token => {
      if (defaultCategories.includes(token.type)) {
        this.categories!.push(token.type);
      }
    });
    return this.categories.filter(onlyUnique);
  }

  getCompounds() {
    if (this.compounds !== null && this.compounds.length > 0) return this.compounds;
    else this.compounds = [];
    let doublePreviousToken: null | Token = null;
    let previousToken: null | Token = null;
    this.tokens.forEach(token => {
      if (['string', 'term'].includes(token.type)) {
        const doublePreviousIsTermOrWord = doublePreviousToken && ['string', 'term'].includes(doublePreviousToken.type);
        const previousIsTermOrWord = previousToken && ['string', 'term'].includes(previousToken.type);
        if (doublePreviousIsTermOrWord && previousIsTermOrWord && (`${doublePreviousToken!.value} ${previousToken!.value} ${token.value}`).length > 6) {
          this.compounds!.push(`${doublePreviousToken!.value} ${previousToken!.value} ${token.value}`);
        } 
        if (previousIsTermOrWord && (previousToken!.value + ' ' + token.value).length > 6) {
          this.compounds!.push(`${previousToken!.value} ${token.value}`);
        }
        if (previousToken) doublePreviousToken = JSON.parse(JSON.stringify(previousToken));
        previousToken = JSON.parse(JSON.stringify(token));
      } else if (!['WS', 'ignore', 'stopList'].includes(token.type)) {
        doublePreviousToken = null;
        previousToken = null;
      }
    });
    return this.compounds.filter(onlyUnique);
  }

  getTerms() {
    if (this.terms !== null && this.terms.length > 0) return this.terms;
    else this.terms = [];
    this.tokens.forEach(token => {
      if (token.type === 'term') {
        this.terms!.push(token.value);
      }
    });
    return this.terms;
  }

  getNumbers() {
    if (this.numbers !== null && this.numbers.length > 0) return this.numbers;
    else this.numbers = [];
    this.tokens.forEach(token => {
      if (token.type === 'number') {
        this.numbers!.push(Number(token.value));
      }
    });
    return this.numbers.filter(onlyUnique);
  } 
}