import SqlJs from 'sql.js';
import { BANK_TRANSACTIONS_TABLE, SUGGESTION, UN_RECONCILED } from '../common';

type GetNonReconciledBankTransactionsOrderedByAmountReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number, bankId: number};

const getNonReconciledBankTransactionsOrderedByAmount = (db: SqlJs.Database) => {
  const SQL = `SELECT id, amount, operationType, date, absAmount, bankId FROM ${BANK_TRANSACTIONS_TABLE} WHERE status = '${UN_RECONCILED}' OR status = '${SUGGESTION}' ORDER BY absAmount DESC`;
  const result = db.exec(SQL);
  const results: GetNonReconciledBankTransactionsOrderedByAmountReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4], bankId: row[5]});
  });
  return results;
}

export { getNonReconciledBankTransactionsOrderedByAmount };
export type { GetNonReconciledBankTransactionsOrderedByAmountReturn };