export const RECONCILED = 'Reconciled';
export const UN_RECONCILED = 'Unreconciled';
export const SUGGESTION = 'Suggestion';
export const CONFLICTING = 'Conflicting';
export const REVERSED = 'Reversed';
export const CREDIT = 'credit';
export const DEBIT = 'debit';
export const LEDGER_ENTRY = 'Ledger Entry';
export const BANK_TRANSACTION = 'Bank Transaction';
export const BANK_TRANSACTIONS_TABLE = 'BankTransactions';
export const LEDGER_ENTRIES_TABLE = 'LedgerEntries';