import { BankTransaction } from '../../BankTransaction';
import { LedgerEntry } from '../../LedgerEntry';
import { LedgerTransactionGroup } from '../../LedgerTransactionGroup';
import { Reconciliation } from '../../Reconciliation';
import { OperationType, RECONCILED, RuleNameType, RuleNames, TOO_HIGH_NUMBER, UN_RECONCILED } from '../../common';
import { getOtherUnreconciledLedgerEntriesWithSameDate } from '../getOtherUnreconciledLedgerEntriesWithSameDate';
import { GetOtherLedgerEntriesReturn, getOtherUnreconciledLedgerEntries } from '../getOtherUnreconciledLedgerEntries';
import { Candidate, findMatchingCombinations } from '../helpers';
import { getNonReconciledBankTransactionsOrderedByAmount } from '../getNonReconciledBankTransactionsOrderedByAmount';
import { getOtherUnreconciledBankTransactionsWithSameDateAndAmount } from '../getOtherUnreconciledBankTransactionsWithSameDateAndAmount';
import { getOtherUnreconciledBankTransactionsWithSameAmount } from '../getOtherUnreconciledBankTransactionsWithSameAmount';
import { getNonReconciledBankTransactionsWithTermTags } from '../getNonReconciledBankTransactionsWithTermTags';

const UNIQUE_COMBINATION_THRESHOLD = 0.5;
const DEBUG_LEDGER_TRANSACTION_GROUP = '0+0';
const DEBUG_TRANSACTION = 0;
const DEBUG_RESULTS = false;

type State = {
  TRANSACTION_IDs_MATCH: boolean,
  AMOUNTS_MATCH: boolean | null,
  AMOUNTS_UNIQUE: boolean | null,
  DATES_MATCH: boolean | null,
  DATES_UNIQUE: boolean | null,
  TAGS_MATCH: boolean | null,
  TAGS_COMBINATION_UNIQUE: boolean | null,
};

type StateOptions = 'AMOUNTS_MATCH' | 'AMOUNTS_UNIQUE' | 'DATES_MATCH' | 'DATES_UNIQUE' | 'TAGS_MATCH' | 'TAGS_COMBINATION_UNIQUE'; 

const s = {
  amountsMatch: 'AMOUNTS_MATCH' as StateOptions,
  amountsUnique: 'AMOUNTS_UNIQUE' as StateOptions,
  datesMatch: 'DATES_MATCH' as StateOptions,
  datesUnique: 'DATES_UNIQUE' as StateOptions,
  tagsMatch: 'TAGS_MATCH' as StateOptions,
  tagsCombinationUnique: 'TAGS_COMBINATION_UNIQUE' as StateOptions,
}

class Rule5 {
  private r: Reconciliation;
  private rule: RuleNames;
  private ledgerTransactionGroup: LedgerTransactionGroup;
  private ledgerEntry: LedgerEntry | null = null;
  private transaction: BankTransaction | null = null;
  private continue = false;
  private _state: State = {
    TRANSACTION_IDs_MATCH: false,
    AMOUNTS_MATCH: null,
    AMOUNTS_UNIQUE: null,
    DATES_MATCH: null,
    DATES_UNIQUE: null,
    TAGS_MATCH: null,
    TAGS_COMBINATION_UNIQUE: null,
  };

  constructor(reconciliation: Reconciliation, rule: RuleNameType) {
    this.r = reconciliation;
    this.rule = rule;
    this._state.TRANSACTION_IDs_MATCH = rule === RuleNames.Rule5_1;
    this.ledgerTransactionGroup = new LedgerTransactionGroup(this.r.ledger);
  }

  resetState(specificValue?: StateOptions): void {
    if (specificValue) {
      this._state[specificValue] = null;
      return;
    } else {
      this.ledgerTransactionGroup = new LedgerTransactionGroup(this.r.ledger);
      this._state.AMOUNTS_MATCH = null;
      this._state.AMOUNTS_UNIQUE = null;
      this._state.DATES_MATCH = null;
      this._state.DATES_UNIQUE = null;
      this._state.TAGS_MATCH = null;
      this._state.TAGS_COMBINATION_UNIQUE = null;
    }
  }

  setState(specificValue: StateOptions, value: boolean): void {
    this._state[specificValue] = value;
  }

  get state(): {
    transactionIdsMatch: () => boolean,
    amountsMatch: () => boolean | null,
    amountsUnique: () => boolean | null,
    datesMatch: () => boolean | null,
    datesUnique: () => boolean | null,
    tagsMatch: () => boolean | null,
    tagsCombinationUnique: () => boolean | null,
  } {
    return {
      transactionIdsMatch: () => this._state.TRANSACTION_IDs_MATCH,
      amountsMatch: () => {
        if (this._state.AMOUNTS_MATCH === null && this.transaction && this.ledgerEntry) this.checkIfAmountsMatch(this.transaction, this.ledgerEntry);
        return this._state.AMOUNTS_MATCH;
      },
      amountsUnique: () => this._state.AMOUNTS_UNIQUE,
      datesMatch: () => this._state.DATES_MATCH,
      datesUnique: () => {
        if (this._state.DATES_UNIQUE === null && this.transaction && this.ledgerEntry) this.checkIfDatesAreUnique(this.transaction, this.ledgerEntry);
        return this._state.DATES_UNIQUE;
      },
      tagsMatch: () => {
        if (this._state.TAGS_MATCH === null && this.transaction) this.checkIfTagsMatch(this.transaction, this.ledgerTransactionGroup);
        return this._state.TAGS_MATCH;
      },
      tagsCombinationUnique: () => {
        if (this._state.TAGS_COMBINATION_UNIQUE === null && this.transaction && this.ledgerEntry) this.checkIfTagsCombinationUnique(this.transaction, this.ledgerEntry, this.ledgerTransactionGroup);
        return this._state.TAGS_COMBINATION_UNIQUE;
      },
    };
  }

  checkIfDatesMatch(transaction: BankTransaction, ledgerEntry: LedgerEntry): void {
    this.setState(s.datesMatch, transaction.getDate().getTime() === ledgerEntry.getDate().getTime());
  }

  checkIfDatesAreUnique(transaction: BankTransaction, ledgerEntry: LedgerEntry): void {
    const nonUniqueBankTransactionsWithSameDate = getOtherUnreconciledBankTransactionsWithSameDateAndAmount(Reconciliation.db, transaction);
    const otherLedgerTransactionsWithSameDate = getOtherUnreconciledLedgerEntriesWithSameDate(Reconciliation.db, transaction, ledgerEntry);
    const nonUniqueLedgerTransactionCombinationsOnSameDate = findMatchingCombinations(otherLedgerTransactionsWithSameDate, -transaction.getAccountingAmount(), {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()});
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) console.log('nonUniqueBankTransactionsWithSameDate', nonUniqueBankTransactionsWithSameDate);
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) console.log('otherLedgerTransactionsWithSameDate', otherLedgerTransactionsWithSameDate);
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) console.log('nonUniqueLedgerTransactionCombinationsOnSameDate', nonUniqueLedgerTransactionCombinationsOnSameDate);
    this.setState(s.datesUnique, (nonUniqueBankTransactionsWithSameDate.length === 0 && nonUniqueLedgerTransactionCombinationsOnSameDate.length === 0));
  }

  checkIfAmountsMatch(transaction: BankTransaction, ledgerEntry: LedgerEntry): void {
    const otherUnreconciledLedgerEntries = getOtherUnreconciledLedgerEntries(Reconciliation.db, ledgerEntry, this.state.transactionIdsMatch());
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) {
      console.log(ledgerEntry.getId(), 'otherUnreconciledLedgerEntries', JSON.stringify(otherUnreconciledLedgerEntries));
    }
    this.ledgerTransactionGroup = new LedgerTransactionGroup(this.r.ledger);
    if (otherUnreconciledLedgerEntries.length === 0) {
      this.continue = true;
      return;
    }
    const targetAmount = (transaction.getAccountingAmount() + ledgerEntry.getAccountingAmount()) * -1;
    if (targetAmount  === 0) {
      this.continue = true;
      return;
    }
    // Get all combinations of ledger statements that sum up to the target amount (i.e. 1 bank to N ledgers)
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) {
      console.log(ledgerEntry.getId(), 'finding combos');
    }
    const combinations = findMatchingCombinations(otherUnreconciledLedgerEntries, targetAmount, {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()});
    if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) {
      console.log(ledgerEntry.getId(), 'combos', JSON.stringify(combinations));
    }
    this.ledgerTransactionGroup.addTransaction(ledgerEntry);
    this.setState(s.amountsMatch, combinations.length > 0);
    
    if (combinations.length === 0) {
      this.continue = true;
      return;
    }
    else if (combinations.length === 1) {
      combinations[0].forEach((t: Candidate) => {
        this.ledgerTransactionGroup.addTransaction(this.r.ledger.getEntries()[t.id-1]);
      });
      // console.log('ledgerTransactionGroup', this.ledgerTransactionGroup.getId());
    } else {
      // find the combination that has the least average distance based on id
      // if there are multiple combinations with the same average distance, then 
      // we will choose the one that contains the transaction with the id closest to the main transaction
      const currentWinningCombination = { id: -TOO_HIGH_NUMBER, averageDistance: TOO_HIGH_NUMBER};
      for (let i=0; i < combinations.length; i++) {
        // console.log('combination', i, combinations[i]);
        const combination = combinations[i];
        const candidateAverageDistance = combination.reduce((acc: number, t: any) => acc + Math.abs(t.id - ledgerEntry.getId()), 0) / combination.length;
        if (currentWinningCombination.averageDistance > candidateAverageDistance) {
          currentWinningCombination.id = i;
          currentWinningCombination.averageDistance = candidateAverageDistance;
        } else if (currentWinningCombination.averageDistance === candidateAverageDistance) {
          // find the transaction in combinations[i] with the id closest to the main transaction
          const candidateCombinationClosestId = combinations[i].reduce((acc: number, t: any) => Math.abs(t.id - ledgerEntry.getId()) < Math.abs(acc - ledgerEntry.getId()) ? t.id : acc, TOO_HIGH_NUMBER);
          const currentWinningCombinationClosestId = combinations[currentWinningCombination.id].reduce((acc: number, t: any) => Math.abs(t.id - ledgerEntry.getId()) < Math.abs(acc - ledgerEntry.getId()) ? t.id : acc, TOO_HIGH_NUMBER);
          
          if (Math.abs(candidateCombinationClosestId - ledgerEntry.getId()) < Math.abs(currentWinningCombinationClosestId - ledgerEntry.getId())) {
            currentWinningCombination.id = i;
            currentWinningCombination.averageDistance = candidateAverageDistance;
          } else if (Math.abs(candidateCombinationClosestId - ledgerEntry.getId()) === Math.abs(currentWinningCombinationClosestId - ledgerEntry.getId())) {
            // if the absolute distance of the closest id is the same, then choose the id that is after the main transaction
            if (candidateCombinationClosestId > currentWinningCombinationClosestId) {
              currentWinningCombination.id = i;
              currentWinningCombination.averageDistance = candidateAverageDistance;
            }
          }
        }
      }
      currentWinningCombination.id !== -TOO_HIGH_NUMBER && combinations[currentWinningCombination.id].forEach((t: any) => {
        this.ledgerTransactionGroup.addTransaction(this.r.ledger.getEntries()[t.id-1])
      });
    }
    // console.log('getting non unique transactions');
    // check if there is another transaction with the same amount
    const nonUniqueTransactions = getOtherUnreconciledBankTransactionsWithSameAmount(Reconciliation.db, transaction);
    // console.log('nonUniqueTransactions', nonUniqueTransactions.length);
    // findCombinations(ledgerEntriesWithSameDate, ledgerEntry.getAccountingAmount(), transaction.operationType as OperationType);
    
    // reduce the list of all other unreconciled ledger entries to only those that are not in included in this.ledgerTransactionGroup.getIds()
    const allOtherUnreconciledLedgerEntries = getOtherUnreconciledLedgerEntries(Reconciliation.db, ledgerEntry).filter((le: GetOtherLedgerEntriesReturn) => !this.ledgerTransactionGroup.getIds().includes(le.id));

    // console.log('finding all other combinations', allOtherUnreconciledLedgerEntries.length, -transaction.getAccountingAmount(), ledgerEntry.operationType as OperationType);
    if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) {
      console.log(ledgerEntry.getId(), 'finding all other combinations', ledgerEntry.getId(), allOtherUnreconciledLedgerEntries, -transaction.getAccountingAmount(), {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()});
    }
    const nonUniqueLedgerEntryCombinations: Candidate[][] = [];
    if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) {
      console.log(ledgerEntry.getId(), 'allOtherUnreconciledLedgerEntries.filter((le: GetOtherLedgerEntriesReturn) => le.id < otherLedgerEntry.id)', allOtherUnreconciledLedgerEntries.length);
    }
    for (let i=0; i < allOtherUnreconciledLedgerEntries.length; i++) {
      const otherLedgerEntry = allOtherUnreconciledLedgerEntries[i];
      findMatchingCombinations(allOtherUnreconciledLedgerEntries.filter((le: GetOtherLedgerEntriesReturn) => le.id < otherLedgerEntry.id), -transaction.getAccountingAmount(), {date: otherLedgerEntry.date, operationType: otherLedgerEntry.operationType as OperationType}).forEach((row: any) => {
        if (transaction.getId() === DEBUG_TRANSACTION && ledgerEntry.getId() === Number(DEBUG_LEDGER_TRANSACTION_GROUP.split('+')[0])) console.log('adding row', row)
        nonUniqueLedgerEntryCombinations.push(row);
      });
    }
    // const nonUniqueLedgerEntryCombinations = findMatchingCombinations(allOtherUnreconciledLedgerEntries, -transaction.getAccountingAmount(), {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()}); 
    
    if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) {
      console.log(ledgerEntry.getId(), 'found all other combinations', JSON.stringify(nonUniqueLedgerEntryCombinations));
    }
    //getUnreconciledLedgerEntriesWithAmount(Reconciliation.db, ledgerEntry.getAccountingAmount()).filter((l: any) => l.id !== ledgerEntry.getId());
    // console.log('nonUniqueLedgerEntryCombinations', nonUniqueLedgerEntryCombinations.length);
    const transactionCombinationIsUniqueBasedOnAmount = (nonUniqueTransactions.length === 0 && nonUniqueLedgerEntryCombinations.length === 0);
    if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('nonUniqueTransactions', nonUniqueTransactions, 'nonUniqueLedgerEntryCombinations', nonUniqueLedgerEntryCombinations)
    this.setState(s.amountsUnique, transactionCombinationIsUniqueBasedOnAmount);
    // console.log('checked if amounts match');
  }

  checkIfTagsMatch(transaction: BankTransaction, ledgerTransactionGroup: LedgerTransactionGroup): void {
    const commonTermTags = this.r.getCommonTermTags(transaction, ledgerTransactionGroup);
    this.setState(s.tagsMatch, commonTermTags.length > 0);
  }

  checkIfTagsCombinationUnique(transaction: BankTransaction, ledgerEntry: LedgerEntry, ledgerTransactionGroup: LedgerTransactionGroup): void {
    const commonTermStatistics = { totalCount: 0, conflictsCount: 0 };
    if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('checkIfTagsCombinationUnique', transaction.getId(), ledgerEntry.getId(), ledgerTransactionGroup.getId(), this.state.tagsMatch());
    if (this.state.tagsMatch()) {
      const commonTermTags = this.r.getCommonTermTags(transaction, ledgerTransactionGroup);
      const allOtherUnreconciledLedgerEntries = getOtherUnreconciledLedgerEntries(Reconciliation.db, ledgerEntry);
      if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('commonTermTags', commonTermTags);
      commonTermTags.forEach((tag: string) => {
        commonTermStatistics.totalCount++;
        const ledgerEntries: Candidate[][] = [];
        for (let i=0; i < allOtherUnreconciledLedgerEntries.length; i++) {
          const otherLedgerEntry = allOtherUnreconciledLedgerEntries[i];
          findMatchingCombinations(allOtherUnreconciledLedgerEntries.filter((le: GetOtherLedgerEntriesReturn) => le.id < otherLedgerEntry.id), -transaction.getAccountingAmount(), {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()}).forEach((row: any) => {
            ledgerEntries.push(row);
          });
        }
        // const ledgerEntries = findMatchingCombinations(allOtherUnreconciledLedgerEntries, -transaction.getAccountingAmount(), {date: ledgerEntry.getDate().getTime(), operationType: ledgerEntry.getOperationType()});
        if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('ledgerEntries', ledgerEntries, allOtherUnreconciledLedgerEntries, transaction.getAccountingAmount());
        if (ledgerEntries) {
          let listOfLedgerTransactionIdsToCheck: number[] = [];
          ledgerEntries.forEach((row: any) => row.forEach((t: any) => listOfLedgerTransactionIdsToCheck.push(t.id)));
          listOfLedgerTransactionIdsToCheck = [...new Set(listOfLedgerTransactionIdsToCheck)];
          const conflictingLedgerTransactions = this.r.getLedger().getEntries().filter((t: any) => t.getStatus() === UN_RECONCILED && listOfLedgerTransactionIdsToCheck.includes(t.getId()) && t.getTags().includes(tag));
          if (conflictingLedgerTransactions.length > 0) {
            if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('conflictingLedgerTransactions', conflictingLedgerTransactions);
            commonTermStatistics.conflictsCount++;
          }
        }
        const conflictingBankTransactions = getNonReconciledBankTransactionsWithTermTags(Reconciliation.db, tag, {amount: -ledgerTransactionGroup.getAmount()}).filter((c: Candidate) => c.id !== transaction.getId()); // TODO confirm if we need to check the date as well, date: transaction.getDate().getTime(), 
        if (conflictingBankTransactions.length > 0) {
          if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('conflictingBankTransactions', tag, conflictingBankTransactions, this.r.bankStatement.getTransactionById(conflictingBankTransactions[0].id).getTags());
          commonTermStatistics.conflictsCount++;
        }
      });
      if (transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('s.tagsCombinationUnique', commonTermStatistics.conflictsCount, '/', commonTermStatistics.totalCount, '=', (commonTermStatistics.totalCount - commonTermStatistics.conflictsCount) / commonTermStatistics.totalCount * 100, '% vs', UNIQUE_COMBINATION_THRESHOLD*100,'%');
      this.setState(s.tagsCombinationUnique, (commonTermStatistics.totalCount - commonTermStatistics.conflictsCount) / commonTermStatistics.totalCount > UNIQUE_COMBINATION_THRESHOLD);
    }
  }

  checkAllTransactionsForMatches(): number {
    let reconciliations = 0;
    // Get unreconciled transactions ordered by amount desc
    const bankTransactionsOrderedByAmount = getNonReconciledBankTransactionsOrderedByAmount(Reconciliation.db);
    // For each transaction
    for (let i = 0; i < bankTransactionsOrderedByAmount.length; i++) {
      this.r.updateProgress();
      this.resetState();
      this.transaction = this.r.bankStatement.getTransactionById(bankTransactionsOrderedByAmount[i].id);
      const ledgerEntries = this.r.getLedger().getNonReconciledEntries();
      // console.log('this.transaction.getId()', this.transaction.getId());
      
      for (let j=0; j < ledgerEntries.length; j++) {
        this.continue = false;
        this.resetState(s.datesMatch);
        this.resetState(s.amountsMatch);
        this.resetState(s.tagsCombinationUnique);
        if (this.transaction.getStatus() === RECONCILED) break;
        this.ledgerEntry = ledgerEntries[j];
        // if (this.transaction.getId() === 144) 
        // console.log('ledgerEntry', this.ledgerEntry?.getId()); //  && this.ledgerEntry?.getId() === 135
        if (this.transaction.getAccountingAmount() === this.ledgerEntry.getAccountingAmount()) {
          console.log('continuing');
          continue;
        }
        if (this.ledgerEntry.getStatus() === RECONCILED) continue;

        if (this.state.amountsMatch()) {
          if (this.continue) {
            continue;
          }
          this.checkIfDatesMatch(this.transaction, this.ledgerEntry);
          if (this.state.datesMatch()) {
            if (this.state.amountsUnique()) {
              // Case 5.1 - Reconcile
              if (DEBUG_RESULTS) console.log('Case 5.1 - Reconcile', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
              this.r.reconcile(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
              reconciliations++;
            } else {
              if (this.state.tagsMatch() && this.state.tagsCombinationUnique()) {
                // Case 5.4 - Reconcile
                if (DEBUG_RESULTS) console.log('Case 5.4 - Reconcile', this._state, this.transaction, this.ledgerTransactionGroup.getIds());
                this.r.reconcile(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
                reconciliations++;
              } else if (!this.state.tagsMatch() || (this.state.tagsMatch() && !this.state.tagsCombinationUnique())) { 
                if (this.state.datesUnique()) {
                  // Cases 5.5 & 5.7 - Suggest
                  if (DEBUG_RESULTS && !this.state.tagsMatch()) console.log('Case 5.5 - Suggest', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
                  if (DEBUG_RESULTS && (this.state.tagsMatch() && !this.state.tagsCombinationUnique())) console.log('Case 5.7 - Suggest', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
                  this.r.suggest(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
                }
              }
            }
          } else {
            if (this.state.amountsUnique()) {
              if (this.state.tagsMatch()) {
                // Case 5.2 - Reconcile
                if (DEBUG_RESULTS) console.log('Case 5.2 - Reconcile', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
                this.r.reconcile(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
                reconciliations++;
              } else {
                // Case 5.3 - Suggest
                if (DEBUG_RESULTS) console.log('Case 5.3 - Suggest', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
                this.r.suggest(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
              }
            } else if (this.state.tagsMatch() && this.state.tagsCombinationUnique()) {
              // Case 5.6 - Suggest
              if (DEBUG_RESULTS) console.log('Case 5.6 - Suggest', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
              this.r.suggest(this.transaction, this.ledgerTransactionGroup.getIds(), this.rule, this._state);
            }
          }
        } else {
          if (DEBUG_RESULTS) console.log('No matches found...');
        }
        if (this.transaction.getId() === DEBUG_TRANSACTION && this.ledgerTransactionGroup.getId() === DEBUG_LEDGER_TRANSACTION_GROUP) console.log('State', this._state, this.transaction.getId(), this.ledgerTransactionGroup.getIds());
      }
    }
    return reconciliations;
  }

  run(): void {
    let pass = 0;
    if (Reconciliation.db) this.r.populateDB(Reconciliation.db);
    let reconciliations = 0;
    do {
      reconciliations = this.checkAllTransactionsForMatches();
      const message = `Running ${this.rule} pass ${++pass}`;
      this.r.setMessage(message + ' - ' + this.r.getBankStatement().getReconciledTransactions().length + ' / ' + this.r.getBankStatement().getTransactions().length);
      console.log(message, `- Reconciliations: ${reconciliations}`);
    } while (reconciliations > 0);
  }

}

export { Rule5 };
