enum RuleNames {
  Rule3_1 = 'Rule 3.1',
  Rule3_2 = 'Rule 3.2',
  Rule3_3 = 'Rule 3.3',
  Rule3_4 = 'Rule 3.4',
  Rule3_5 = 'Rule 3.5',
  Rule3_6 = 'Rule 3.6',
  Rule4_1 = 'Rule 4.1',
  Rule4_2 = 'Rule 4.2',
  Rule5_1 = 'Rule 5.1',
  Rule5_2 = 'Rule 5.2',
  Rule6_1 = 'Rule 6.1',
}

type RuleNameType = 
  RuleNames.Rule3_1 | RuleNames.Rule3_2 | RuleNames.Rule3_3 | RuleNames.Rule3_4 | RuleNames.Rule3_5 | RuleNames.Rule3_6
  | RuleNames.Rule4_1 | RuleNames.Rule4_2 | RuleNames.Rule5_1 | RuleNames.Rule5_2 | RuleNames.Rule6_1;

export type { RuleNameType };
export { RuleNames };