import React from 'react';

function LineItem (props: any) {
  const { title, amount, typeOfBalance } = props;
  return (
    <table style={{fontSize: '18px', textAlign: 'left', marginBottom: '20px'}}>
          <tbody>
            <tr style={{fontWeight: '800'}}>
              <td style={{width: '750px'}}>{title}</td>
              <td>{amount}</td>
              <td>{typeOfBalance}</td>
            </tr>
          </tbody>
      </table>
  );
}

export { LineItem };
