import { Reconciliation } from '../../Reconciliation';
import { RuleNames, } from '../../common';
import { getNonReconciledLedgerEntriesWithSameAmount } from '../getNonReconciledLedgerEntriesWithSameAmount';
import { getOtherNonReconciledBankTransactionsWithSameAmount } from '../getOtherNonReconciledBankTransactionsWithSameAmount';
import { getUnreconciledLedgerEntriesWithSameAmount } from '../getUnreconciledLedgerEntriesWithSameAmount';
import { Candidate } from '../helpers';

class Rule6 {
  private r: Reconciliation;
  private rule: RuleNames;

  constructor(reconciliation: Reconciliation, rule: RuleNames) {
    this.r = reconciliation;
    this.rule = rule;
  }

  run(): void {
    const rule = this.rule;
    if (Reconciliation.db) this.r.populateDB(Reconciliation.db);
    
    const state: {
      BANK_IDs_MATCH: boolean | null,
      AMOUNTS_MATCH: boolean | null,
      AMOUNTS_UNIQUE: boolean | null,
      DATES_MATCH: boolean | null,
      DATES_UNIQUE: boolean | null,
      TAGS_MATCH: boolean | null,
      TAGS_COMBINATION_UNIQUE: boolean | null,
      id: null | number,
    } = {
      BANK_IDs_MATCH: null,
      AMOUNTS_MATCH: null,
      AMOUNTS_UNIQUE: null,
      DATES_MATCH: null,
      DATES_UNIQUE: null,
      TAGS_MATCH: null,
      TAGS_COMBINATION_UNIQUE: null,
      id: null,
    };
    let reconciliations = 0;
    let pass = 0;
    do {
      // For each transaction
      pass++;
      reconciliations = 0;
      const message = `Running ${rule} pass ${pass}`;
      this.r.setMessage(message + ' - ' + this.r.getBankStatement().getReconciledTransactions().length + ' / ' + this.r.getBankStatement().getTransactions().length);
      this.r.delay(10);
      const nonReconciledTransactions = this.r.getBankStatement().getNonReconciledTransactions();
      for (let i = 0; i < nonReconciledTransactions.length; i++) {
        const transaction = nonReconciledTransactions[i];
        this.r.updateProgress();
        state.id = transaction.getId();
        state.AMOUNTS_MATCH = null;
        state.AMOUNTS_UNIQUE = null;
        state.DATES_MATCH = null;
        state.DATES_UNIQUE = null;
        state.TAGS_MATCH = null;
        state.TAGS_COMBINATION_UNIQUE = null;
        const otherNonReconciledBankTransactionsWithSameAmount = getOtherNonReconciledBankTransactionsWithSameAmount(Reconciliation.db, transaction);
        // const otherUnreconciledBankTransactionsWithSameAmount = getOtherUnreconciledBankTransactionsWithSameAmount(Reconciliation.db, transaction);
        if (otherNonReconciledBankTransactionsWithSameAmount.length === 0) {
          state.AMOUNTS_UNIQUE = true;
        } else {
          state.AMOUNTS_UNIQUE = false;
        }
        const unNonReconciledLedgerEntriesWithSameAmount = getNonReconciledLedgerEntriesWithSameAmount(Reconciliation.db, transaction);
        const unreconciledLedgerEntriesWithSameAmount = getUnreconciledLedgerEntriesWithSameAmount(Reconciliation.db, transaction);
        if (unNonReconciledLedgerEntriesWithSameAmount.length === 0) state.AMOUNTS_MATCH = false;
        else {
          state.AMOUNTS_MATCH = true;
          state.DATES_MATCH = unNonReconciledLedgerEntriesWithSameAmount.filter((ledgerTransaction: Candidate) => ledgerTransaction.date === transaction.getDate().getTime()).length > 0;
          state.DATES_UNIQUE = unNonReconciledLedgerEntriesWithSameAmount.filter((ledgerTransaction: Candidate) => ledgerTransaction.date === transaction.getDate().getTime()).length === 1;
          state.AMOUNTS_UNIQUE = unNonReconciledLedgerEntriesWithSameAmount.length === 1;
        }

        if (state.AMOUNTS_MATCH && state.AMOUNTS_UNIQUE) {
          if (state.DATES_MATCH) {
            // Reconcile
            this.r.reconcile(transaction, [unNonReconciledLedgerEntriesWithSameAmount[0].id], rule);
            reconciliations++;
          } else if (unreconciledLedgerEntriesWithSameAmount.length > 0) {
            // Suggest
            this.r.suggest(transaction, [unreconciledLedgerEntriesWithSameAmount[0].id], rule);
          }
        } else if (state.AMOUNTS_MATCH && !state.AMOUNTS_UNIQUE) {
          if (state.DATES_MATCH && state.DATES_UNIQUE && unreconciledLedgerEntriesWithSameAmount.length > 0) {
            // Suggest
            this.r.suggest(transaction, [unreconciledLedgerEntriesWithSameAmount[0].id], rule);
          } 
        }
      }
      console.log(message, `- Reconciliations: ${reconciliations}`);
    } while (reconciliations > 0);
  }

}

export { Rule6 };