import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, UN_RECONCILED } from '../common';
import { LedgerEntry } from '../LedgerEntry';

type GetOtherLedgerEntriesReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};

const getOtherUnreconciledLedgerEntries = (db: SqlJs.Database, ledgerEntry: LedgerEntry, forceSameLedgerId?: boolean) => {
  let SQL = `SELECT id, amount, operationType, date, absAmount FROM ${LEDGER_ENTRIES_TABLE} WHERE status = '${UN_RECONCILED}' AND id <> ?`;
  let params: any = [ledgerEntry.getId()];
  if (forceSameLedgerId !== undefined) {
    SQL += ' AND date = ?' + (forceSameLedgerId ? ' AND ledgerId = ? AND ledgerId <> \'\'' : '');
    params = forceSameLedgerId ? [ledgerEntry.getId(), ledgerEntry.getDate().getTime(), ledgerEntry.getLedgerId()] : [ledgerEntry.getId(), ledgerEntry.getDate().getTime()];
  }
  const result = db.exec(SQL, params);
  const results: GetOtherLedgerEntriesReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getOtherUnreconciledLedgerEntries };
export type { GetOtherLedgerEntriesReturn };