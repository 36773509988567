import { CONFLICTING, RECONCILED, SUGGESTION, UN_RECONCILED, CREDIT, DEBIT, LEDGER_ENTRY, BANK_TRANSACTION, REVERSED } from './strings';

export enum OperationTypes {
  Credit = CREDIT,
  Debit = DEBIT,
}
export enum TransactionTypes {
  LedgerEntry = LEDGER_ENTRY,
  BankTransaction =  BANK_TRANSACTION,
}

export enum OrderByTypes {
  TermFrequency = 'Term Frequency',
}

export enum TransactionStatuses {
  Reconciled = RECONCILED,
  Unreconciled = UN_RECONCILED,
  Suggestion = SUGGESTION,
  Conflicting = CONFLICTING,
  Reversed = REVERSED,
}

export const ReconciledStatuses = [
  TransactionStatuses.Reconciled,
];

export const NonReconciledStatuses = [
  TransactionStatuses.Unreconciled, 
  TransactionStatuses.Suggestion, 
  TransactionStatuses.Conflicting
];



