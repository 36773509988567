import SqlJs from 'sql.js';
import { BANK_TRANSACTIONS_TABLE, SUGGESTION, UN_RECONCILED } from '../common';

type GetNonReconciledBankTransactionsWithTermTagsReturn = {id: number, amount: number, operationType: string, date: number, absAmount: number};
type Options = {date?: number, amount?: number};

const getNonReconciledBankTransactionsWithTermTags = (db: SqlJs.Database, tag: string, options?: Options) => {
  let SQL = `SELECT id, amount, operationType, date, absAmount FROM ${BANK_TRANSACTIONS_TABLE} WHERE (status = '${UN_RECONCILED}' OR status = '${SUGGESTION}') AND (LOWER(description) LIKE '%${tag}%' OR LOWER(reference) LIKE '%${tag}%')`;
  if (options?.date) SQL += ` AND date = ${options.date}`;
  if (options?.amount) SQL += ` AND amount = ${options.amount}`;
  const result = db.exec(SQL);
  const results: GetNonReconciledBankTransactionsWithTermTagsReturn[] = [];
  if (result[0]) result[0].values.forEach((row: any) => {
    results.push({id: row[0], amount: row[1], operationType: row[2], date: row[3], absAmount: row[4]});
  });
  return results;
}

export { getNonReconciledBankTransactionsWithTermTags };
export type { GetNonReconciledBankTransactionsWithTermTagsReturn };