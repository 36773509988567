import SqlJs from 'sql.js';
import {LEDGER_ENTRIES_TABLE, BANK_TRANSACTIONS_TABLE, } from '../common';
import { BankTransactionsStatement } from '../BankTransactionsStatement';
import { Ledger } from '../Ledger';

const populateDatabase = (db: SqlJs.Database, bankStatement: BankTransactionsStatement, ledger: Ledger) => {
  if (db) {
    console.log('Populating DB');
    db.run(`DROP TABLE IF EXISTS ${BANK_TRANSACTIONS_TABLE}`);
    db.run(`CREATE TABLE ${BANK_TRANSACTIONS_TABLE} (id, date, description, bankId, reference, absAmount, amount, operationType, status)`);
    bankStatement.getTransactions().forEach((transaction) => {
      db.run(`INSERT INTO ${BANK_TRANSACTIONS_TABLE} (id, date, description, bankId, reference, absAmount, amount, operationType, status) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)`, [
        transaction.getId(),
        transaction.getDate().getTime(),
        transaction.getDescription(),
        transaction.getBankId(),
        transaction.getReference(),
        transaction.getAmount(),
        transaction.getAccountingAmount(),
        transaction.getOperationType(),
        transaction.getStatus(),
      ]);
    });
    db.run(`DROP TABLE IF EXISTS ${LEDGER_ENTRIES_TABLE}`);
    db.run(`CREATE TABLE ${LEDGER_ENTRIES_TABLE} (id, date, description, ledgerId, reference, absAmount, amount, operationType, status)`);
    ledger.getEntries().forEach((entry) => {
      db.run(`INSERT INTO ${LEDGER_ENTRIES_TABLE} (id, date, description, ledgerId, reference, absAmount, amount, operationType, status) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)`, [
        entry.getId(),
        entry.getDate().getTime(),
        entry.getDescription(),
        entry.getLedgerId(),
        entry.getReference(),
        entry.getAmount(),
        entry.getAccountingAmount(),
        entry.getOperationType(),
        entry.getStatus(),
      ]);
    });
  } else {
    throw new Error('DB not initialized!');
  }    
}

export { populateDatabase };